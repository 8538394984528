"use strict";
import { useEffect } from "react";
function isAnimating(node) {
  return (node?.getAnimations?.().length ?? 0) > 0;
}
export function useUnmountingAnimation(node, getAnimatingClass, animatedElements, skip = false) {
  useEffect(() => {
    const current = node.current;
    const animated = animatedElements?.map((element) => element.current) ?? [current];
    const parent = current?.parentElement;
    const removeChild = parent?.removeChild;
    if (!(parent && removeChild) || skip) {
      return;
    }
    parent.removeChild = function(child) {
      if (child === current && animated) {
        animated.forEach((element) => element?.classList.add(getAnimatingClass()));
        const animating = animated.find((element) => isAnimating(element ?? void 0));
        if (animating) {
          animating?.addEventListener("animationend", (x) => {
            if (x.target === animating) {
              removeChild.call(parent, child);
            }
          });
        } else {
          removeChild.call(parent, child);
        }
        return child;
      } else {
        return removeChild.call(parent, child);
      }
    };
    return () => {
      parent.removeChild = removeChild;
    };
  }, [animatedElements, getAnimatingClass, node, skip]);
}
