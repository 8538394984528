"use strict";
import { TradeType } from "@uniswap/sdk-core";
import { parseUnits } from "ethers/lib/utils";
import { gqlToCurrency, supportedChainIdFromGQLChain } from "graphql/data/util";
import store from "state";
import { addSignature } from "state/signatures/reducer";
import { SignatureType } from "state/signatures/types";
import { TransactionType as LocalTransactionType } from "state/transactions/types";
import { UniswapXOrderStatus } from "types/uniswapx";
import { SwapOrderStatus, SwapOrderType } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { logger } from "utilities/src/logger/logger";
import { currencyId } from "utils/currencyId";
const SIGNATURE_TYPE_MAP = {
  [SwapOrderType.Limit]: SignatureType.SIGN_LIMIT,
  [SwapOrderType.Dutch]: SignatureType.SIGN_UNISWAPX_ORDER,
  [SwapOrderType.DutchV2]: SignatureType.SIGN_UNISWAPX_V2_ORDER
};
const ORDER_STATUS_MAP = {
  [SwapOrderStatus.Open]: UniswapXOrderStatus.OPEN,
  [SwapOrderStatus.Expired]: UniswapXOrderStatus.EXPIRED,
  [SwapOrderStatus.Error]: UniswapXOrderStatus.ERROR,
  [SwapOrderStatus.InsufficientFunds]: UniswapXOrderStatus.INSUFFICIENT_FUNDS,
  [SwapOrderStatus.Filled]: UniswapXOrderStatus.FILLED,
  [SwapOrderStatus.Cancelled]: UniswapXOrderStatus.CANCELLED
};
export function parseRemote({ chain, details, timestamp }) {
  const chainId = supportedChainIdFromGQLChain(chain);
  if (!chainId) {
    const error = new Error("Invalid activity from unsupported chain received from GQL");
    logger.error(error, {
      tags: {
        file: "parseRemote",
        function: "parseRemote"
      },
      extra: { details }
    });
    throw error;
  }
  const status = ORDER_STATUS_MAP[details.orderStatus];
  const isFilled = status === UniswapXOrderStatus.FILLED;
  const inputTokenQuantity = parseUnits(details.inputTokenQuantity, details.inputToken.decimals).toString();
  const outputTokenQuantity = parseUnits(details.outputTokenQuantity, details.outputToken.decimals).toString();
  if (inputTokenQuantity === "0" || outputTokenQuantity === "0") {
    throw new Error("Invalid activity received from GQL");
  }
  const signature = {
    id: details.id,
    type: SIGNATURE_TYPE_MAP[details.swapOrderType],
    offerer: details.offerer,
    chainId,
    orderHash: details.hash,
    expiry: details.expiry,
    encodedOrder: details.encodedOrder,
    addedTime: timestamp,
    ...isFilled ? {
      status: UniswapXOrderStatus.FILLED,
      txHash: details.hash
    } : {
      status,
      txHash: void 0
    },
    swapInfo: {
      isUniswapXOrder: true,
      type: LocalTransactionType.SWAP,
      // This doesn't affect the display, but we don't know this value from the remote activity.
      tradeType: TradeType.EXACT_INPUT,
      inputCurrencyId: currencyId(gqlToCurrency(details.inputToken)),
      outputCurrencyId: currencyId(gqlToCurrency(details.outputToken)),
      inputCurrencyAmountRaw: inputTokenQuantity,
      expectedOutputCurrencyAmountRaw: outputTokenQuantity,
      minimumOutputCurrencyAmountRaw: outputTokenQuantity,
      settledOutputCurrencyAmountRaw: isFilled ? outputTokenQuantity : void 0
    }
  };
  if (status === UniswapXOrderStatus.OPEN) {
    setTimeout(() => {
      store.dispatch(addSignature(signature));
    }, 0);
  }
  return signature;
}
