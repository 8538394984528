"use strict";
import { meldSupportedCurrencyToCurrencyInfo } from "graphql/data/types";
import { useActiveLocalCurrency } from "hooks/useActiveLocalCurrency";
import { useActiveLocale } from "hooks/useActiveLocale";
import { useMemo } from "react";
import {
  useFiatOnRampAggregatorSupportedFiatCurrenciesQuery,
  useFiatOnRampAggregatorSupportedTokensQuery
} from "uniswap/src/features/fiatOnRamp/api";
import { useTranslation } from "uniswap/src/i18n";
import { getFiatCurrencyComponents } from "utilities/src/format/localeBased";
import { getFiatCurrencyName } from "utils/fiatCurrency";
export const fallbackCurrencyInfo = {
  ...getFiatCurrencyComponents("en-US", "USD"),
  symbol: "$",
  name: "United States Dollar",
  shortName: "USD",
  code: "USD"
};
export function useMeldFiatCurrencyInfo(selectedCountry) {
  const { data: supportedFiatCurrencies } = useFiatOnRampAggregatorSupportedFiatCurrenciesQuery({
    countryCode: selectedCountry?.countryCode ?? "US"
  });
  const activeLocale = useActiveLocale();
  const activeLocalCurrency = useActiveLocalCurrency();
  const { t } = useTranslation();
  const appFiatCurrencySupported = supportedFiatCurrencies && supportedFiatCurrencies.fiatCurrencies.some(
    (currency) => activeLocalCurrency.toLowerCase() === currency.fiatCurrencyCode.toLowerCase()
  );
  const meldSupportedFiatCurrency = useMemo(() => {
    const activeLocalCurrencyComponents = getFiatCurrencyComponents(activeLocale, activeLocalCurrency);
    const { name, shortName } = getFiatCurrencyName(t, activeLocalCurrency);
    const activeLocalCurrencyFiatCurrencyInfo = {
      ...activeLocalCurrencyComponents,
      name,
      shortName,
      code: activeLocalCurrency
    };
    return appFiatCurrencySupported ? activeLocalCurrencyFiatCurrencyInfo : fallbackCurrencyInfo;
  }, [activeLocalCurrency, activeLocale, appFiatCurrencySupported, t]);
  return {
    meldSupportedFiatCurrency,
    notAvailableInThisRegion: supportedFiatCurrencies?.fiatCurrencies?.length === 0
  };
}
export function useFiatOnRampSupportedTokens(fiatCurrency, countryCode) {
  const { data: quoteCurrencyOptions } = useFiatOnRampAggregatorSupportedTokensQuery({
    fiatCurrency: fiatCurrency.code,
    countryCode: countryCode ?? "US"
  });
  return useMemo(() => {
    return quoteCurrencyOptions?.supportedTokens?.map((currency) => {
      const meldCurrencyCode = currency.cryptoCurrencyCode;
      const currencyInfo = meldSupportedCurrencyToCurrencyInfo(currency);
      return { currencyInfo, meldCurrencyCode };
    }) ?? [];
  }, [quoteCurrencyOptions?.supportedTokens]);
}
