"use strict";
import { useReportTotalBalancesUsdForAnalytics } from "graphql/data/apollo/TokenBalancesProvider";
import { useAccount } from "hooks/useAccount";
import usePrevious from "hooks/usePrevious";
import { useUpdateAtom } from "jotai/utils";
import { multicallUpdaterSwapChainIdAtom } from "lib/hooks/useBlockNumber";
import { useEffect, useMemo, useState } from "react";
import { useDerivedSwapInfo } from "state/swap/hooks";
import { SwapAndLimitContext, SwapContext, initialSwapState } from "state/swap/types";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { SwapTab } from "uniswap/src/types/screens/interface";
import { areCurrenciesEqual } from "uniswap/src/utils/currencyId";
export function SwapAndLimitContextProvider({
  children,
  initialChainId,
  initialInputCurrency,
  initialOutputCurrency,
  multichainUXEnabled
}) {
  const [selectedChainId, setSelectedChainId] = useState(initialChainId);
  const [isUserSelectedToken, setIsUserSelectedToken] = useState(false);
  const [currentTab, setCurrentTab] = useState(SwapTab.Swap);
  const [currencyState, setCurrencyState] = useState({
    inputCurrency: initialInputCurrency,
    outputCurrency: initialOutputCurrency
  });
  const prefilledState = useMemo(
    () => ({
      inputCurrency: initialInputCurrency,
      outputCurrency: initialOutputCurrency
    }),
    [initialInputCurrency, initialOutputCurrency]
  );
  const account = useAccount();
  const previousInitialInputCurrency = usePrevious(initialInputCurrency);
  const previousInitialOutputCurrency = usePrevious(initialOutputCurrency);
  const previousInitialChainId = usePrevious(initialChainId);
  useEffect(() => {
    if (!areCurrenciesEqual(previousInitialInputCurrency, initialInputCurrency) || !areCurrenciesEqual(previousInitialOutputCurrency, initialOutputCurrency)) {
      setCurrencyState(prefilledState);
    }
  }, [
    initialInputCurrency,
    initialOutputCurrency,
    prefilledState,
    previousInitialInputCurrency,
    previousInitialOutputCurrency
  ]);
  useEffect(() => {
    if (multichainUXEnabled && !isUserSelectedToken && previousInitialInputCurrency && areCurrenciesEqual(previousInitialInputCurrency, initialInputCurrency)) {
      setCurrencyState(prefilledState);
    }
  }, [initialInputCurrency, isUserSelectedToken, multichainUXEnabled, prefilledState, previousInitialInputCurrency]);
  useEffect(() => {
    if (!isUserSelectedToken && initialChainId && previousInitialChainId !== initialChainId) {
      setSelectedChainId(initialChainId);
      if (!multichainUXEnabled) {
        setCurrencyState(prefilledState);
      }
    }
  }, [
    initialChainId,
    isUserSelectedToken,
    multichainUXEnabled,
    prefilledState,
    previousInitialChainId,
    setSelectedChainId
  ]);
  const setMulticallUpdaterChainId = useUpdateAtom(multicallUpdaterSwapChainIdAtom);
  useEffect(() => {
    const chainId = (multichainUXEnabled ? selectedChainId : account.chainId) ?? void 0;
    setMulticallUpdaterChainId(chainId);
  }, [account.chainId, multichainUXEnabled, selectedChainId, setMulticallUpdaterChainId]);
  const value = useMemo(() => {
    return {
      currencyState,
      setCurrencyState,
      setSelectedChainId,
      currentTab,
      setCurrentTab,
      prefilledState,
      initialChainId,
      chainId: (multichainUXEnabled ? selectedChainId : account.chainId) ?? void 0,
      multichainUXEnabled,
      isSwapAndLimitContext: true,
      isUserSelectedToken,
      setIsUserSelectedToken
    };
  }, [
    initialChainId,
    account.chainId,
    selectedChainId,
    currencyState,
    currentTab,
    prefilledState,
    multichainUXEnabled,
    isUserSelectedToken
  ]);
  useReportTotalBalancesUsdForAnalytics();
  return <SwapAndLimitContext.Provider value={value}>{children}</SwapAndLimitContext.Provider>;
}
export function SwapContextProvider({
  initialTypedValue,
  initialIndependentField,
  multichainUXEnabled,
  children
}) {
  const [swapState, setSwapState] = useState({
    typedValue: initialTypedValue ?? initialSwapState.typedValue,
    independentField: initialIndependentField ?? initialSwapState.independentField
  });
  const derivedSwapInfo = useDerivedSwapInfo(swapState);
  const { chainId: connectedChainId } = useAccount();
  const previousConnectedChainId = usePrevious(connectedChainId);
  const { chainId: swapChainId } = useSwapAndLimitContext();
  const previousSwapChainId = usePrevious(swapChainId);
  useEffect(() => {
    const connectedChainIdChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId;
    const swapChainIdChanged = previousSwapChainId && previousSwapChainId !== swapChainId;
    if (multichainUXEnabled) {
      if (swapChainIdChanged) {
        setSwapState((prev) => ({ ...prev, typedValue: "" }));
      }
      return;
    }
    if (connectedChainIdChanged) {
      setSwapState((prev) => ({ ...prev, typedValue: "" }));
    }
  }, [connectedChainId, previousConnectedChainId, swapChainId, previousSwapChainId, multichainUXEnabled]);
  return <SwapContext.Provider value={{ swapState, setSwapState, derivedSwapInfo }}>{children}</SwapContext.Provider>;
}
