import { useEffect, useRef, useState } from "react";
import { Popover } from "tamagui";
import { MenuContent } from "ui/src/components/menu/MenuContent";
import { useSporeColors } from "ui/src/hooks/useSporeColors";
import { useOnClickOutside, usePrevious } from "utilities/src/react/hooks";
export function ContextMenu({
  children,
  menuOptions,
  menuStyleProps,
  itemId,
  onLeftClick = false,
  closeOnClick = false,
  ...rest
}) {
  const lastItemId = usePrevious(itemId);
  const colors = useSporeColors();
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    if (lastItemId && itemId !== lastItemId) {
      setShowMenu(false);
    }
  }, [itemId, lastItemId]);
  const onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowMenu(true);
  };
  const menuContainerRef = useRef(null);
  useOnClickOutside(menuContainerRef, () => setShowMenu(false));
  const triggerContainerRef = useRef(null);
  const { offset: customOffset, placement } = rest;
  const offset = customOffset || placement && placement !== "bottom-end" ? customOffset : -(triggerContainerRef.current?.offsetHeight ?? 0);
  const contentShadowProps = {
    shadowColor: colors.shadowColor.val,
    shadowRadius: 12,
    shadowOpacity: 0.1
  };
  return <Popover offset={offset} open={showMenu} placement="bottom-end" {...rest}>
    <Popover.Trigger><div
      ref={triggerContainerRef}
      onClick={onLeftClick ? onContextMenu : void 0}
      onContextMenu={onLeftClick ? void 0 : onContextMenu}
    >{children}</div></Popover.Trigger>
    <Popover.Content
      ref={menuContainerRef}
      animation={[
        "quick",
        {
          opacity: {
            overshootClamping: true
          }
        }
      ]}
      borderColor="$surface3"
      borderRadius="$rounded16"
      borderWidth="$spacing1"
      disableRemoveScroll={false}
      enterStyle={{ y: -10, opacity: 0 }}
      exitStyle={{ y: -10, opacity: 0 }}
      p="$none"
      {...contentShadowProps}
    >
      <div ref={menuContainerRef}><MenuContent
        items={menuOptions}
        onClose={closeOnClick ? () => setShowMenu(false) : void 0}
        {...menuStyleProps}
      /></div>
      <Popover.Arrow backgroundColor="transparent" />
    </Popover.Content>
  </Popover>;
}
