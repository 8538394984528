"use strict";
import { useActiveLocale } from "hooks/useActiveLocale";
import { useCallback } from "react";
export function useHeaderDateFormatter() {
  const locale = useActiveLocale();
  return useCallback(
    (time) => {
      if (!time) {
        return "-";
      }
      const headerTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      };
      return new Date(time * 1e3).toLocaleString(locale, headerTimeFormatOptions);
    },
    [locale]
  );
}
