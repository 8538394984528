"use strict";
import { useAccount } from "hooks/useAccount";
import { useContext } from "react";
import { SwapAndLimitContext, SwapContext } from "state/swap/types";
export function useSwapContext() {
  return useContext(SwapContext);
}
export function useSwapAndLimitContext() {
  const account = useAccount();
  const context = useContext(SwapAndLimitContext);
  return {
    ...context,
    chainId: context.isSwapAndLimitContext ? context.chainId : account.chainId
  };
}
