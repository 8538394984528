"use strict";
import Expand from "components/Expand";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import { Input, InputContainer } from "components/Settings/Input";
import { DEFAULT_DEADLINE_FROM_NOW } from "constants/misc";
import ms from "ms";
import { useState } from "react";
import { useUserTransactionTTL } from "state/user/hooks";
import { ThemedText } from "theme/components";
import { Trans } from "uniswap/src/i18n";
var DeadlineError = /* @__PURE__ */ ((DeadlineError2) => {
  DeadlineError2["InvalidInput"] = "InvalidInput";
  return DeadlineError2;
})(DeadlineError || {});
const THREE_DAYS_IN_SECONDS = ms(`3d`) / 1e3;
const NUMBERS_ONLY = /^[0-9\b]+$/;
export default function TransactionDeadlineSettings() {
  const [deadline, setDeadline] = useUserTransactionTTL();
  const defaultInputValue = deadline && deadline !== DEFAULT_DEADLINE_FROM_NOW ? (deadline / 60).toString() : "";
  const [deadlineInput, setDeadlineInput] = useState(defaultInputValue);
  const [deadlineError, setDeadlineError] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultInputValue.length > 0);
  function parseCustomDeadline(value) {
    if (value.length > 0 && !NUMBERS_ONLY.test(value)) {
      return;
    }
    setDeadlineInput(value);
    setDeadlineError(false);
    if (value.length === 0) {
      setDeadline(DEFAULT_DEADLINE_FROM_NOW);
      return;
    }
    try {
      const parsed = Number.parseInt(value) * 60;
      if (parsed === 0 || parsed > THREE_DAYS_IN_SECONDS) {
        setDeadlineError("InvalidInput" /* InvalidInput */);
      } else {
        setDeadline(parsed);
      }
    } catch (error) {
      setDeadlineError("InvalidInput" /* InvalidInput */);
    }
  }
  return <Expand
    isOpen={isOpen}
    onToggle={() => setIsOpen(!isOpen)}
    padding="6px 0px"
    testId="transaction-deadline-settings"
    header={<Row width="auto">
      <ThemedText.BodyPrimary><Trans i18nKey="swap.transaction.deadline" /></ThemedText.BodyPrimary>
      <QuestionHelper text={<Trans i18nKey="swap.transaction.revertAfter" />} />
    </Row>}
    button={<Trans i18nKey="common.time.minute.amt" values={{ time: deadline / 60 }} />}
  ><Row><InputContainer gap="md" error={!!deadlineError}>
    <Input
      data-testid="deadline-input"
      placeholder={(DEFAULT_DEADLINE_FROM_NOW / 60).toString()}
      value={deadlineInput}
      onChange={(e) => parseCustomDeadline(e.target.value)}
      onBlur={() => {
        setDeadlineInput(defaultInputValue);
        setDeadlineError(false);
      }}
    />
    <ThemedText.BodyPrimary><Trans i18nKey="common.time.minutes" /></ThemedText.BodyPrimary>
  </InputContainer></Row></Expand>;
}
