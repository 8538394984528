"use strict";
import { chainIdToBackendChain, useIsSupportedChainId } from "constants/chains";
import { useAccount } from "hooks/useAccount";
import { useEffect } from "react";
import { useAppSelector } from "state/hooks";
export const useOnGlobalChainSwitch = (callback) => {
  const { chainId } = useAccount();
  const isSupportedChain = useIsSupportedChainId(chainId);
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain);
  useEffect(() => {
    if (isSupportedChain && chainId === switchingChain) {
      const chainName = chainIdToBackendChain({ chainId });
      callback(chainId, chainName);
    }
  }, [callback, chainId, isSupportedChain, switchingChain]);
};
