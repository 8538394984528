"use strict";
import { TradeType } from "@uniswap/sdk-core";
import { useSupportedChainId } from "constants/chains";
import { BigNumber } from "ethers/lib/ethers";
import { useAccount } from "hooks/useAccount";
import useSelectChain from "hooks/useSelectChain";
import { useUniswapXSwapCallback } from "hooks/useUniswapXSwapCallback";
import { useUniversalRouterSwapCallback } from "hooks/useUniversalRouter";
import { useCallback } from "react";
import { OffchainOrderType, TradeFillType } from "state/routing/types";
import { isClassicTrade, isUniswapXTrade } from "state/routing/utils";
import { useAddOrder } from "state/signatures/hooks";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { useTransactionAdder } from "state/transactions/hooks";
import {
  TransactionType
} from "state/transactions/types";
import { currencyId } from "utils/currencyId";
function getUniversalRouterFeeFields(trade) {
  if (!isClassicTrade(trade)) {
    return void 0;
  }
  if (!trade.swapFee) {
    return void 0;
  }
  if (trade.tradeType === TradeType.EXACT_INPUT) {
    return { feeOptions: { fee: trade.swapFee.percent, recipient: trade.swapFee.recipient } };
  } else {
    return { flatFeeOptions: { amount: BigNumber.from(trade.swapFee.amount), recipient: trade.swapFee.recipient } };
  }
}
export function useSwapCallback(trade, fiatValues, allowedSlippage, permitSignature) {
  const addTransaction = useTransactionAdder();
  const addOrder = useAddOrder();
  const account = useAccount();
  const supportedConnectedChainId = useSupportedChainId(account.chainId);
  const { chainId: swapChainId } = useSwapAndLimitContext();
  const uniswapXSwapCallback = useUniswapXSwapCallback({
    trade: isUniswapXTrade(trade) ? trade : void 0,
    allowedSlippage,
    fiatValues
  });
  const universalRouterSwapCallback = useUniversalRouterSwapCallback(
    isClassicTrade(trade) ? trade : void 0,
    fiatValues,
    {
      slippageTolerance: allowedSlippage,
      permit: permitSignature,
      ...getUniversalRouterFeeFields(trade)
    }
  );
  const selectChain = useSelectChain();
  const swapCallback = isUniswapXTrade(trade) ? uniswapXSwapCallback : universalRouterSwapCallback;
  return useCallback(async () => {
    if (!trade) {
      throw new Error("missing trade");
    } else if (!account.isConnected || !account.address) {
      throw new Error("wallet must be connected to swap");
    } else if (!swapChainId) {
      throw new Error("missing swap chainId");
    } else if (!supportedConnectedChainId || supportedConnectedChainId !== swapChainId) {
      const correctChain = await selectChain(swapChainId);
      if (!correctChain) {
        throw new Error("wallet must be connected to correct chain to swap");
      }
    }
    const result = await swapCallback();
    const swapInfo = {
      type: TransactionType.SWAP,
      inputCurrencyId: currencyId(trade.inputAmount.currency),
      outputCurrencyId: currencyId(trade.outputAmount.currency),
      isUniswapXOrder: result.type === TradeFillType.UniswapX || result.type === TradeFillType.UniswapXv2,
      ...trade.tradeType === TradeType.EXACT_INPUT ? {
        tradeType: TradeType.EXACT_INPUT,
        inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
        expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
        minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(allowedSlippage).quotient.toString()
      } : {
        tradeType: TradeType.EXACT_OUTPUT,
        maximumInputCurrencyAmountRaw: trade.maximumAmountIn(allowedSlippage).quotient.toString(),
        outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
        expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString()
      }
    };
    switch (result.type) {
      case TradeFillType.UniswapX:
      case TradeFillType.UniswapXv2:
        addOrder(
          account.address,
          result.response.orderHash,
          supportedConnectedChainId,
          // satisfies type-checker; already checked & switched chain above if !supportedConnectedChainId
          result.response.deadline,
          swapInfo,
          result.response.encodedOrder,
          isUniswapXTrade(trade) ? trade.offchainOrderType : OffchainOrderType.DUTCH_AUCTION
          // satisfying type-checker; isUniswapXTrade should always be true
        );
        break;
      default:
        addTransaction(result.response, swapInfo, result.deadline?.toNumber());
    }
    return result;
  }, [
    account.address,
    account.isConnected,
    addOrder,
    addTransaction,
    allowedSlippage,
    selectChain,
    supportedConnectedChainId,
    swapCallback,
    swapChainId,
    trade
  ]);
}
