"use strict";
import { InterfaceElementName } from "@uniswap/analytics-events";
import { useAccountDrawer, useSetShowMoonpayText } from "components/AccountDrawer/MiniPortfolio/hooks";
import { MouseoverTooltip } from "components/Tooltip";
import { SwapHeaderTabButton } from "components/swap/styled";
import { useAccount } from "hooks/useAccount";
import { useCallback, useEffect, useState } from "react";
import { useFiatOnrampAvailability, useOpenModal } from "state/application/hooks";
import { ApplicationModal } from "state/application/reducer";
import { ExternalLink } from "theme/components";
import Trace from "uniswap/src/features/telemetry/Trace";
import { Trans } from "uniswap/src/i18n";
import { isPathBlocked } from "utils/blockedPaths";
export const MOONPAY_REGION_AVAILABILITY_ARTICLE = "https://support.uniswap.org/hc/en-us/articles/11306664890381-Why-isn-t-MoonPay-available-in-my-region-";
var BuyFiatFlowState = /* @__PURE__ */ ((BuyFiatFlowState2) => {
  BuyFiatFlowState2[BuyFiatFlowState2["INACTIVE"] = 0] = "INACTIVE";
  BuyFiatFlowState2[BuyFiatFlowState2["ACTIVE_CHECKING_REGION"] = 1] = "ACTIVE_CHECKING_REGION";
  BuyFiatFlowState2[BuyFiatFlowState2["ACTIVE_NEEDS_ACCOUNT"] = 2] = "ACTIVE_NEEDS_ACCOUNT";
  return BuyFiatFlowState2;
})(BuyFiatFlowState || {});
export default function SwapBuyFiatButton({
  triggerBuyFlow,
  setTriggerBuyFlow
}) {
  const account = useAccount();
  const openFiatOnRampModal = useOpenModal(ApplicationModal.FIAT_ONRAMP);
  const shouldShowBuyFiatButton = !isPathBlocked("/buy");
  const [checkFiatRegionAvailability, setCheckFiatRegionAvailability] = useState(false);
  const {
    available: fiatOnrampAvailable,
    availabilityChecked: fiatOnrampAvailabilityChecked,
    loading: fiatOnrampAvailabilityLoading
  } = useFiatOnrampAvailability(checkFiatRegionAvailability);
  const [buyFiatFlowState, setBuyFiatFlowState] = useState(0 /* INACTIVE */);
  const accountDrawer = useAccountDrawer();
  const setShowMoonpayTextInDrawer = useSetShowMoonpayText();
  const handleBuyCrypto = useCallback(() => {
    if (!fiatOnrampAvailabilityChecked) {
      setCheckFiatRegionAvailability(true);
      setBuyFiatFlowState(1 /* ACTIVE_CHECKING_REGION */);
    } else if (fiatOnrampAvailable && !account.isConnected && !accountDrawer.isOpen) {
      setShowMoonpayTextInDrawer(true);
      accountDrawer.open();
      setBuyFiatFlowState(2 /* ACTIVE_NEEDS_ACCOUNT */);
    } else if (fiatOnrampAvailable && account.isConnected) {
      openFiatOnRampModal();
      setBuyFiatFlowState(0 /* INACTIVE */);
    } else if (!fiatOnrampAvailable) {
      setBuyFiatFlowState(0 /* INACTIVE */);
    }
  }, [
    fiatOnrampAvailabilityChecked,
    fiatOnrampAvailable,
    account.isConnected,
    accountDrawer,
    setShowMoonpayTextInDrawer,
    openFiatOnRampModal
  ]);
  useEffect(() => {
    if (triggerBuyFlow) {
      handleBuyCrypto();
      setTriggerBuyFlow?.(false);
    }
  }, [handleBuyCrypto, setTriggerBuyFlow, triggerBuyFlow]);
  useEffect(() => {
    if (buyFiatFlowState === 1 /* ACTIVE_CHECKING_REGION */ && fiatOnrampAvailabilityChecked || account.isConnected && buyFiatFlowState === 2 /* ACTIVE_NEEDS_ACCOUNT */) {
      handleBuyCrypto();
    }
  }, [account.isConnected, handleBuyCrypto, buyFiatFlowState, fiatOnrampAvailabilityChecked]);
  const buyCryptoButtonDisabled = !fiatOnrampAvailable && fiatOnrampAvailabilityChecked || fiatOnrampAvailabilityLoading || // When wallet drawer is open AND user is in the connect wallet step of the buy fiat flow, disable buy fiat button.
  accountDrawer.isOpen && buyFiatFlowState === 2 /* ACTIVE_NEEDS_ACCOUNT */;
  const fiatOnRampsUnavailableTooltipDisabled = !fiatOnrampAvailabilityChecked || fiatOnrampAvailabilityChecked && fiatOnrampAvailable;
  if (!shouldShowBuyFiatButton) {
    return null;
  }
  return <MouseoverTooltip
    text={<div data-testid="fiat-on-ramp-unavailable-tooltip">
      <Trans i18nKey="fiatOnRamp.notAvailable.error" />
      <Trace logPress element={InterfaceElementName.FIAT_ON_RAMP_LEARN_MORE_LINK}><ExternalLink href={MOONPAY_REGION_AVAILABILITY_ARTICLE} style={{ paddingLeft: "4px" }}><Trans i18nKey="common.button.learn" /></ExternalLink></Trace>
    </div>}
    placement="bottom"
    disabled={fiatOnRampsUnavailableTooltipDisabled}
  ><Trace
    logPress
    element={InterfaceElementName.FIAT_ON_RAMP_BUY_BUTTON}
    properties={{ account_connected: account.isConnected }}
  ><SwapHeaderTabButton
    $isActive={false}
    onClick={handleBuyCrypto}
    disabled={buyCryptoButtonDisabled}
    data-testid="buy-fiat-button"
  ><Trans i18nKey="common.buy.label" /></SwapHeaderTabButton></Trace></MouseoverTooltip>;
}
