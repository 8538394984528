"use strict";
import { Experiments } from "uniswap/src/features/gating/experiments";
import { useExperimentGroupNameWithLoading } from "uniswap/src/features/gating/hooks";
export var AccountCTAsExperimentGroup = /* @__PURE__ */ ((AccountCTAsExperimentGroup2) => {
  AccountCTAsExperimentGroup2["Control"] = "Control";
  AccountCTAsExperimentGroup2["SignInSignUp"] = "SignIn-SignUp";
  AccountCTAsExperimentGroup2["LogInCreateAccount"] = "LogIn-CreateAccount";
  return AccountCTAsExperimentGroup2;
})(AccountCTAsExperimentGroup || {});
export function useIsAccountCTAExperimentControl() {
  const { value: experimentGroupName, isLoading } = useExperimentGroupNameWithLoading(Experiments.AccountCTAs);
  return {
    isControl: experimentGroupName === "Control" /* Control */ || experimentGroupName === null,
    isLoading
  };
}
