"use strict";
import { InterfaceElementName, InterfaceEventName } from "@uniswap/analytics-events";
import { useAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { ButtonLight, ButtonPrimary } from "components/Button";
import Column from "components/Column";
import { useIsSupportedChainId } from "constants/chains";
import { useAccount } from "hooks/useAccount";
import { useGroupedRecentTransfers } from "hooks/useGroupedRecentTransfers";
import useSelectChain from "hooks/useSelectChain";
import { useSendCallback } from "hooks/useSendCallback";
import { NewAddressSpeedBumpModal } from "pages/Swap/Send/NewAddressSpeedBump";
import SendCurrencyInputForm from "pages/Swap/Send/SendCurrencyInputForm";
import { SendRecipientForm } from "pages/Swap/Send/SendRecipientForm";
import { SendReviewModal } from "pages/Swap/Send/SendReviewModal";
import { SmartContractSpeedBumpModal } from "pages/Swap/Send/SmartContractSpeedBump";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SendContextProvider, useSendContext } from "state/send/SendContext";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import Trace from "uniswap/src/features/telemetry/Trace";
import { InterfacePageNameLocal } from "uniswap/src/features/telemetry/constants";
import { Trans } from "uniswap/src/i18n";
import { useIsSmartContractAddress } from "utils/transfer";
function useSendButtonState() {
  const { sendState, derivedSendInfo } = useSendContext();
  const { recipient } = sendState;
  const { parsedTokenAmount, recipientData } = derivedSendInfo;
  return useMemo(() => {
    if (recipient && !recipientData) {
      return {
        label: <Trans i18nKey="common.invalidRecipient.error" />,
        disabled: true
      };
    }
    if (!parsedTokenAmount) {
      return {
        label: <Trans i18nKey="common.amountInput.placeholder" />,
        disabled: true
      };
    }
    if (!recipient && !recipientData) {
      return {
        label: <Trans i18nKey="common.input.noRecipient.error" />,
        disabled: true
      };
    }
    return {
      label: <Trans i18nKey="common.send.button" />,
      disabled: false
    };
  }, [parsedTokenAmount, recipient, recipientData]);
}
var SendFormModalState = /* @__PURE__ */ ((SendFormModalState2) => {
  SendFormModalState2["None"] = "None";
  SendFormModalState2["SMART_CONTRACT_SPEED_BUMP"] = "SMART_CONTRACT_SPEED_BUMP";
  SendFormModalState2["NEW_ADDRESS_SPEED_BUMP"] = "NEW_ADDRESS_SPEED_BUMP";
  SendFormModalState2["REVIEW"] = "REVIEW";
  return SendFormModalState2;
})(SendFormModalState || {});
var SendSpeedBump = /* @__PURE__ */ ((SendSpeedBump2) => {
  SendSpeedBump2["SMART_CONTRACT_SPEED_BUMP"] = "SMART_CONTRACT_SPEED_BUMP";
  SendSpeedBump2["NEW_ADDRESS_SPEED_BUMP"] = "NEW_ADDRESS_SPEED_BUMP";
  return SendSpeedBump2;
})(SendSpeedBump || {});
function SendFormInner({ disableTokenInputs = false, onCurrencyChange }) {
  const account = useAccount();
  const selectChain = useSelectChain();
  const accountDrawer = useAccountDrawer();
  const [sendFormModalState, setSendFormModalState] = useState("None" /* None */);
  const [sendFormSpeedBumpState, setSendFormSpeedBumpState] = useState({
    ["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]: false,
    ["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]: false
  });
  const { initialChainId, chainId, multichainUXEnabled } = useSwapAndLimitContext();
  const isSupportedChain = useIsSupportedChainId(chainId);
  const { setSendState, derivedSendInfo } = useSendContext();
  const { inputError, parsedTokenAmount, recipientData, transaction, gasFee } = derivedSendInfo;
  const { isSmartContractAddress, loading: loadingSmartContractAddress } = useIsSmartContractAddress(
    recipientData?.address
  );
  const { transfers: recentTransfers, loading: transfersLoading } = useGroupedRecentTransfers(account.address);
  const isRecentAddress = useMemo(() => {
    if (!recipientData?.address) {
      return void 0;
    }
    return !!recentTransfers?.[recipientData.address];
  }, [recentTransfers, recipientData?.address]);
  const sendButtonState = useSendButtonState();
  const sendCallback = useSendCallback({
    currencyAmount: parsedTokenAmount,
    recipient: recipientData?.address,
    transactionRequest: transaction,
    gasFee
  });
  const handleModalState = useCallback((newState) => {
    setSendFormModalState(newState ?? "None" /* None */);
  }, []);
  useEffect(() => {
    setSendFormSpeedBumpState(() => ({
      ["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]: isSmartContractAddress,
      ["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]: !isRecentAddress
    }));
  }, [isRecentAddress, isSmartContractAddress, recipientData?.address]);
  const handleSendButton = useCallback(
    (prevSpeedBump) => {
      if (prevSpeedBump !== "SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */ && sendFormSpeedBumpState["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]) {
        handleModalState("SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */);
        return;
      }
      if (prevSpeedBump !== "NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */ && sendFormSpeedBumpState["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]) {
        handleModalState("NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */);
        return;
      }
      handleModalState("REVIEW" /* REVIEW */);
    },
    [handleModalState, sendFormSpeedBumpState]
  );
  const handleConfirmSmartContractSpeedBump = useCallback(() => {
    setSendFormSpeedBumpState((prev) => ({
      ...prev,
      ["SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */]: false
    }));
    handleModalState("None" /* None */);
    handleSendButton("SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */);
  }, [handleModalState, handleSendButton]);
  const handleCancelSmartContractSpeedBump = useCallback(
    () => handleModalState("None" /* None */),
    [handleModalState]
  );
  const handleConfirmNewAddressSpeedBump = useCallback(() => {
    setSendFormSpeedBumpState((prev) => ({
      ...prev,
      ["NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */]: false
    }));
    handleModalState("None" /* None */);
    handleSendButton("NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */);
  }, [handleModalState, handleSendButton]);
  const handleCancelNewAddressSpeedBump = useCallback(
    () => handleModalState("None" /* None */),
    [handleModalState]
  );
  const handleSend = useCallback(() => {
    sendCallback().then(() => {
      handleModalState("None" /* None */);
      setSendState((prev) => ({
        ...prev,
        exactAmountToken: void 0,
        exactAmountFiat: "",
        recipient: "",
        validatedRecipient: void 0,
        inputInFiat: true
      }));
    }).catch(() => void 0);
  }, [handleModalState, sendCallback, setSendState]);
  return <>
    <Column gap="xs">
      <SendCurrencyInputForm disabled={disableTokenInputs} onCurrencyChange={onCurrencyChange} />
      <SendRecipientForm disabled={disableTokenInputs} />
      {account.isDisconnected ? <Trace
        logPress
        eventOnTrigger={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
        element={InterfaceElementName.CONNECT_WALLET_BUTTON}
      ><ButtonLight onClick={accountDrawer.open} fontWeight={535} $borderRadius="16px"><Trans i18nKey="common.connectWallet.button" /></ButtonLight></Trace> : !multichainUXEnabled && initialChainId && initialChainId !== account.chainId ? <ButtonPrimary $borderRadius="16px" onClick={async () => await selectChain(initialChainId)}><Trans
        i18nKey="common.connectToChain.button"
        values={{ chainName: isSupportedChain ? UNIVERSE_CHAIN_INFO[initialChainId].label : void 0 }}
      /></ButtonPrimary> : <Trace logPress element={InterfaceElementName.SEND_BUTTON}><ButtonPrimary
        fontWeight={535}
        disabled={!!inputError || loadingSmartContractAddress || transfersLoading || sendButtonState.disabled}
        onClick={() => handleSendButton()}
      >{sendButtonState.label}</ButtonPrimary></Trace>}
    </Column>
    {sendFormModalState === "REVIEW" /* REVIEW */ ? <SendReviewModal onConfirm={handleSend} onDismiss={() => handleModalState("None" /* None */)} /> : sendFormModalState === "SMART_CONTRACT_SPEED_BUMP" /* SMART_CONTRACT_SPEED_BUMP */ ? <SmartContractSpeedBumpModal
      onCancel={handleCancelSmartContractSpeedBump}
      onConfirm={handleConfirmSmartContractSpeedBump}
    /> : sendFormModalState === "NEW_ADDRESS_SPEED_BUMP" /* NEW_ADDRESS_SPEED_BUMP */ ? <NewAddressSpeedBumpModal
      onCancel={handleCancelNewAddressSpeedBump}
      onConfirm={handleConfirmNewAddressSpeedBump}
    /> : null}
  </>;
}
export function SendForm(props) {
  return <Trace page={InterfacePageNameLocal.Send}><SendContextProvider><SendFormInner {...props} /></SendContextProvider></Trace>;
}
