"use strict";
import { useMemo } from "react";
import {
  TransactionType,
  useRecentTokenTransfersQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export function useRecentTokenTransfers(address) {
  const { data, loading } = useRecentTokenTransfersQuery({
    variables: { address: address ?? "" },
    skip: !address
  });
  return useMemo(() => {
    if (!data?.portfolios?.[0]?.assetActivities) {
      return {
        data: void 0,
        loading
      };
    }
    const recentTransactions = data.portfolios[0].assetActivities.filter(
      (activity) => activity && activity.details.type === TransactionType.Send && activity.details.assetChanges.length === 1 && activity.details.assetChanges[0]?.__typename === "TokenTransfer" && activity.details.assetChanges[0].asset.project && !activity.details.assetChanges[0].asset.project?.isSpam
    ).map((activity) => activity.details.assetChanges[0]);
    return {
      data: recentTransactions.length > 0 ? recentTransactions : void 0,
      loading: false
    };
  }, [data, loading]);
}
