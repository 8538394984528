"use strict";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { ConfirmedIcon, LogoContainer, SubmittedIcon } from "components/AccountDrawer/MiniPortfolio/Activity/Logos";
import { useCancelOrdersGasEstimate } from "components/AccountDrawer/MiniPortfolio/Activity/hooks";
import Column from "components/Column";
import { Container, Dialog, DialogButtonType } from "components/Dialog/Dialog";
import { LoaderV3 } from "components/Icons/LoadingSpinner";
import Modal from "components/Modal";
import { GetHelpHeader } from "components/Modal/GetHelpHeader";
import Row from "components/Row";
import { DetailLineItem } from "components/swap/DetailLineItem";
import { nativeOnChain } from "constants/tokens";
import { useStablecoinValue } from "hooks/useStablecoinPrice";
import styled, { useTheme } from "lib/styled-components";
import { Slash } from "react-feather";
import { SignatureType } from "state/signatures/types";
import { ExternalLink, ThemedText } from "theme/components";
import { Plural, Trans, t } from "uniswap/src/i18n";
import { NumberType, useFormatter } from "utils/formatNumbers";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
const GasEstimateContainer = styled(Row)`
  border-top: 1px solid ${({ theme }) => theme.surface3};
  margin-top: 16px;
  padding-top: 16px;
`;
const ModalHeader = styled(GetHelpHeader)`
  padding: 4px 0px;
`;
export var CancellationState = /* @__PURE__ */ ((CancellationState2) => {
  CancellationState2["NOT_STARTED"] = "not_started";
  CancellationState2["REVIEWING_CANCELLATION"] = "reviewing_cancellation";
  CancellationState2["PENDING_SIGNATURE"] = "pending_cancellation_signature";
  CancellationState2["PENDING_CONFIRMATION"] = "pending_cancellation_confirmation";
  CancellationState2["CANCELLED"] = "cancelled";
  return CancellationState2;
})(CancellationState || {});
function useCancelOrdersDialogContent(state, orders) {
  const theme = useTheme();
  switch (state) {
    case "reviewing_cancellation" /* REVIEWING_CANCELLATION */:
      return {
        title: orders.length === 1 && orders[0].type === SignatureType.SIGN_LIMIT ? <Trans i18nKey="common.limit.cancel" count={orders.length} /> : <Trans i18nKey="common.cancelOrder" />,
        icon: <Slash />
      };
    case "pending_cancellation_signature" /* PENDING_SIGNATURE */:
      return {
        title: <Trans i18nKey="common.confirmCancellation" />,
        icon: <LoaderV3 size="64px" color={theme.accent1} />
      };
    case "pending_cancellation_confirmation" /* PENDING_CONFIRMATION */:
      return {
        title: <Trans i18nKey="common.cancellationSubmitted" />,
        icon: <SubmittedIcon />
      };
    case "cancelled" /* CANCELLED */:
      return {
        title: <Trans i18nKey="common.cancellationSuccessful" />,
        icon: <ConfirmedIcon />
      };
    default:
      return {
        title: void 0,
        icon: <Slash />
      };
  }
}
export function CancelOrdersDialog(props) {
  const { orders, cancelState, cancelTxHash, onConfirm, onCancel } = props;
  const { title, icon } = useCancelOrdersDialogContent(cancelState, orders);
  const gasEstimate = useCancelOrdersGasEstimate(orders);
  if (["pending_cancellation_signature" /* PENDING_SIGNATURE */, "pending_cancellation_confirmation" /* PENDING_CONFIRMATION */, "cancelled" /* CANCELLED */].includes(
    cancelState
  )) {
    const cancelSubmitted = (cancelState === "cancelled" /* CANCELLED */ || cancelState === "pending_cancellation_confirmation" /* PENDING_CONFIRMATION */) && cancelTxHash;
    return <Modal isOpen $scrollOverlay onDismiss={onCancel} maxHeight="90vh"><Container gap="lg">
      <ModalHeader closeModal={onCancel} />
      <LogoContainer>{icon}</LogoContainer>
      <ThemedText.SubHeaderLarge width="100%" textAlign="center">{title}</ThemedText.SubHeaderLarge>
      <Row justify="center" marginTop="32px" minHeight="24px">{cancelSubmitted ? <ExternalLink
        href={getExplorerLink(orders[0].chainId, cancelTxHash, ExplorerDataType.TRANSACTION)}
        color="neutral2"
      ><Trans i18nKey="common.viewOnExplorer" /></ExternalLink> : <ThemedText.BodySmall color="neutral2"><Trans i18nKey="common.proceedInWallet" /></ThemedText.BodySmall>}</Row>
    </Container></Modal>;
  } else if (cancelState === "reviewing_cancellation" /* REVIEWING_CANCELLATION */) {
    return <Dialog
      {...props}
      icon={icon}
      title={title}
      description={<Column>
        <Plural
          value={orders.length}
          one={t("swap.cancel.cannotExecute")}
          other={t("swap.cancel.cannotExecute.plural")}
        />
        <GasEstimateDisplay chainId={orders[0].chainId} gasEstimateValue={gasEstimate?.value} />
      </Column>}
      buttonsConfig={{
        left: {
          title: <Trans i18nKey="common.neverMind" />,
          onClick: onCancel,
          textColor: "neutral1"
        },
        right: {
          title: <Trans i18nKey="common.proceed" />,
          onClick: onConfirm,
          type: DialogButtonType.Error,
          disabled: cancelState !== "reviewing_cancellation" /* REVIEWING_CANCELLATION */,
          textColor: "white"
        }
      }}
    />;
  } else {
    return null;
  }
}
function GasEstimateDisplay({ gasEstimateValue, chainId }) {
  const gasFeeCurrencyAmount = CurrencyAmount.fromRawAmount(nativeOnChain(chainId), gasEstimateValue ?? "0");
  const gasFeeUSD = useStablecoinValue(gasFeeCurrencyAmount);
  const { formatCurrencyAmount } = useFormatter();
  const gasFeeFormatted = formatCurrencyAmount({
    amount: gasFeeUSD,
    type: NumberType.PortfolioBalance
  });
  return <GasEstimateContainer><DetailLineItem
    LineItem={{
      Label: () => <Trans i18nKey="common.networkCost" />,
      Value: () => <span>{gasEstimateValue ? gasFeeFormatted : "-"}</span>
    }}
  /></GasEstimateContainer>;
}
