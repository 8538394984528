"use strict";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { createSubscriptionLink } from "utilities/src/apollo/SubscriptionLink";
import { splitSubscription } from "utilities/src/apollo/splitSubscription";
const API_URL = process.env.REACT_APP_AWS_API_ENDPOINT;
const REALTIME_URL = process.env.REACT_APP_AWS_REALTIME_ENDPOINT;
const REALTIME_TOKEN = process.env.REACT_APP_AWS_REALTIME_TOKEN;
if (!API_URL || !REALTIME_URL || !REALTIME_TOKEN) {
  throw new Error("AWS CONFIG MISSING FROM ENVIRONMENT");
}
const httpLink = new HttpLink({ uri: API_URL });
export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: httpLink,
  headers: {
    "Content-Type": "application/json",
    Origin: "https://app.uniswap.org"
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          nftBalances: relayStylePagination(["ownerAddress", "filter"]),
          nftAssets: relayStylePagination(),
          nftActivity: relayStylePagination(),
          token: {
            // Tokens should be cached by their chain/address, *not* by the ID returned by the server.
            // This is because the ID may change depending on fields requested.
            read(_, { args, toReference }) {
              return toReference({ __typename: "Token", chain: args?.chain, address: args?.address?.toLowerCase() });
            }
          }
        }
      },
      Token: {
        // Tokens are cached by their chain/address (see Query.fields.token, above).
        // In any query for `token` or `tokens`, you *must* include `chain` and `address` fields in order
        // to properly normalize the result in the cache.
        keyFields: ["chain", "address"],
        fields: {
          address: {
            // Always cache lowercased for consistency (backend sometimes returns checksummed).
            read(address) {
              return address?.toLowerCase() ?? null;
            }
          }
        }
      },
      TokenProject: {
        fields: {
          tokens: {
            // Cache data may be lost when replacing the tokens array, so retain all known tokens.
            merge(existing, incoming, { toReference }) {
              if (!existing || !incoming) {
                return existing ?? incoming;
              } else {
                const refs = existing.map((token) => toReference(token, true));
                const refSet = refs.reduce((refSet2, ref) => refSet2.add(ref.__ref), /* @__PURE__ */ new Set());
                const newRefs = incoming.map((token) => toReference(token, true)).filter((ref) => !refSet.has(ref.__ref));
                return [...refs, ...newRefs];
              }
            }
          }
        }
      },
      TokenMarket: {
        keyFields: ["id"]
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  }
});
const subscriptionLink = createSubscriptionLink({ uri: REALTIME_URL, token: REALTIME_TOKEN }, apolloClient);
apolloClient.setLink(splitSubscription(subscriptionLink, httpLink));
