"use strict";
import {
  SearchResultType
} from "uniswap/src/features/search/SearchResult";
import { tokenAddressOrNativeAddress } from "uniswap/src/features/search/utils";
import { UniverseChainId } from "uniswap/src/types/chains";
export function organizeSearchResults(isNFTPage, tokenResults, collectionResults) {
  const reducedTokens = tokenResults?.slice(0, isNFTPage ? 3 : collectionResults.length < 3 ? 8 - collectionResults.length : 5) ?? [];
  const reducedCollections = collectionResults.slice(0, 8 - reducedTokens.length);
  return [reducedTokens, reducedCollections];
}
export const searchTokenToTokenSearchResult = (searchToken) => {
  return {
    type: SearchResultType.Token,
    chainId: searchToken.chainId,
    symbol: searchToken.symbol ?? "",
    address: tokenAddressOrNativeAddress(searchToken.address, searchToken.chainId),
    name: searchToken.name ?? null,
    logoUrl: searchToken.project?.logoUrl ?? null,
    safetyLevel: searchToken.project?.safetyLevel ?? null
  };
};
export const searchGenieCollectionToTokenSearchResult = (searchToken) => {
  return {
    type: SearchResultType.NFTCollection,
    chainId: UniverseChainId.Mainnet,
    address: searchToken.address ?? "",
    name: searchToken.name ?? "",
    imageUrl: searchToken.imageUrl,
    isVerified: searchToken.isVerified ?? false
  };
};
