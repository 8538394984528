"use strict";
import { DEFAULT_LOCAL_CURRENCY, SUPPORTED_LOCAL_CURRENCIES } from "constants/localCurrencies";
import { useActiveLocale } from "hooks/useActiveLocale";
import useParsedQueryString from "hooks/useParsedQueryString";
import { atomWithStorage, useAtomValue } from "jotai/utils";
import { useMemo } from "react";
import { getFiatCurrencyComponents } from "utils/formatNumbers";
export const activeLocalCurrencyAtom = atomWithStorage(
  "activeLocalCurrency",
  DEFAULT_LOCAL_CURRENCY
);
function useUrlLocalCurrency() {
  const parsed = useParsedQueryString();
  const parsedLocalCurrency = parsed.cur;
  if (typeof parsedLocalCurrency !== "string") {
    return void 0;
  }
  const lowerCaseSupportedLocalCurrency = parsedLocalCurrency.toLowerCase();
  return SUPPORTED_LOCAL_CURRENCIES.find(
    (localCurrency) => localCurrency.toLowerCase() === lowerCaseSupportedLocalCurrency
  );
}
export function useActiveLocalCurrency() {
  const activeLocalCurrency = useAtomValue(activeLocalCurrencyAtom);
  const urlLocalCurrency = useUrlLocalCurrency();
  return useMemo(() => urlLocalCurrency ?? activeLocalCurrency, [activeLocalCurrency, urlLocalCurrency]);
}
export function useActiveLocalCurrencyComponents() {
  const activeLocale = useActiveLocale();
  const activeLocalCurrency = useActiveLocalCurrency();
  return useMemo(
    () => getFiatCurrencyComponents(activeLocale, activeLocalCurrency),
    [activeLocalCurrency, activeLocale]
  );
}
