"use strict";
import { isAddress } from "utilities/src/addresses";
const alwaysTrue = () => true;
export function getTokenFilter(query) {
  const searchingAddress = isAddress(query);
  if (searchingAddress) {
    const address = searchingAddress.toLowerCase();
    return (t) => "address" in t && address === t.address.toLowerCase();
  }
  const queryParts = query.toLowerCase().split(/\s+/).filter((s) => s.length > 0);
  if (queryParts.length === 0) {
    return alwaysTrue;
  }
  const match = (s) => {
    const parts = s.toLowerCase().split(/\s+/).filter((s2) => s2.length > 0);
    return queryParts.every((p) => p.length === 0 || parts.some((sp) => sp.startsWith(p) || sp.endsWith(p)));
  };
  return ({ name, symbol }) => Boolean(symbol && match(symbol) || name && match(name));
}
