"use strict";
import { InterfacePageName } from "@uniswap/analytics-events";
import { NetworkAlert } from "components/NetworkAlert/NetworkAlert";
import { SwitchLocaleLink } from "components/SwitchLocaleLink";
import SwapHeader from "components/swap/SwapHeader";
import { PageWrapper, SwapWrapper } from "components/swap/styled";
import { useSupportedChainId } from "constants/chains";
import { useScreenSize } from "hooks/screenSize";
import { useAccount } from "hooks/useAccount";
import { BuyForm } from "pages/Swap/Buy/BuyForm";
import { LimitFormWrapper } from "pages/Swap/Limit/LimitForm";
import { SendForm } from "pages/Swap/Send/SendForm";
import { SwapForm } from "pages/Swap/SwapForm";
import { useLocation } from "react-router-dom";
import { TradeState } from "state/routing/types";
import { isPreviewTrade } from "state/routing/utils";
import { SwapAndLimitContextProvider, SwapContextProvider } from "state/swap/SwapContext";
import { useInitialCurrencyState } from "state/swap/hooks";
import { SwapAndLimitContext } from "state/swap/types";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { Flex } from "ui/src";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
import Trace from "uniswap/src/features/telemetry/Trace";
import { SwapTab } from "uniswap/src/types/screens/interface";
export function getIsReviewableQuote(trade, tradeState, swapInputError) {
  if (swapInputError) {
    return false;
  }
  if (isPreviewTrade(trade)) {
    return true;
  }
  return Boolean(trade && tradeState === TradeState.VALID);
}
export default function SwapPage({ className }) {
  const location = useLocation();
  const multichainUXEnabled = useFeatureFlag(FeatureFlags.MultichainUX);
  useFeatureFlag(FeatureFlags.AATestWeb);
  const {
    initialInputCurrency,
    initialOutputCurrency,
    initialChainId,
    initialTypedValue,
    initialField,
    initialCurrencyLoading
  } = useInitialCurrencyState();
  const isUnsupportedConnectedChain = useSupportedChainId(useAccount().chainId) === void 0;
  const shouldDisableTokenInputs = multichainUXEnabled ? false : isUnsupportedConnectedChain;
  return <Trace logImpression page={InterfacePageName.SWAP_PAGE}>
    <PageWrapper><Swap
      className={className}
      chainId={initialChainId}
      multichainUXEnabled={multichainUXEnabled}
      disableTokenInputs={shouldDisableTokenInputs}
      initialInputCurrency={initialInputCurrency}
      initialOutputCurrency={initialOutputCurrency}
      initialTypedValue={initialTypedValue}
      initialIndependentField={initialField}
      initialCurrencyLoading={initialCurrencyLoading}
      syncTabToUrl={true}
    /></PageWrapper>
    {location.pathname === "/swap" && <SwitchLocaleLink />}
  </Trace>;
}
export function Swap({
  className,
  initialInputCurrency,
  initialOutputCurrency,
  initialTypedValue,
  initialIndependentField,
  initialCurrencyLoading = false,
  chainId,
  hideHeader = false,
  onCurrencyChange,
  multichainUXEnabled = false,
  disableTokenInputs = false,
  compact = false,
  syncTabToUrl
}) {
  const isDark = useIsDarkMode();
  const screenSize = useScreenSize();
  const forAggregatorEnabled = useFeatureFlag(FeatureFlags.ForAggregator);
  return <SwapAndLimitContextProvider
    initialChainId={chainId}
    initialInputCurrency={initialInputCurrency}
    initialOutputCurrency={initialOutputCurrency}
    multichainUXEnabled={multichainUXEnabled}
  >
    {
      /* TODO: Move SwapContextProvider inside Swap tab ONLY after SwapHeader removes references to trade / autoSlippage */
    }
    <SwapAndLimitContext.Consumer>{({ currentTab }) => <SwapContextProvider
      initialTypedValue={initialTypedValue}
      initialIndependentField={initialIndependentField}
      multichainUXEnabled={multichainUXEnabled}
    ><Flex width="100%">
      <SwapWrapper isDark={isDark} className={className} id="swap-page">
        {!hideHeader && <SwapHeader compact={compact || !screenSize.sm} syncTabToUrl={syncTabToUrl} />}
        {currentTab === SwapTab.Swap && <SwapForm
          onCurrencyChange={onCurrencyChange}
          initialCurrencyLoading={initialCurrencyLoading}
          disableTokenInputs={disableTokenInputs}
        />}
        {currentTab === SwapTab.Limit && <LimitFormWrapper onCurrencyChange={onCurrencyChange} />}
        {currentTab === SwapTab.Send && <SendForm disableTokenInputs={disableTokenInputs} onCurrencyChange={onCurrencyChange} />}
        {currentTab === SwapTab.Buy && forAggregatorEnabled && <BuyForm disabled={disableTokenInputs} />}
      </SwapWrapper>
      <NetworkAlert />
    </Flex></SwapContextProvider>}</SwapAndLimitContext.Consumer>
  </SwapAndLimitContextProvider>;
}
