"use strict";
import { createSlice, nanoid } from "@reduxjs/toolkit";
import { DEFAULT_TXN_DISMISS_MS } from "constants/misc";
export var PopupType = /* @__PURE__ */ ((PopupType2) => {
  PopupType2["Transaction"] = "transaction";
  PopupType2["Order"] = "order";
  PopupType2["FailedSwitchNetwork"] = "failedSwitchNetwork";
  PopupType2["SwitchNetwork"] = "switchNetwork";
  return PopupType2;
})(PopupType || {});
export var ApplicationModal = /* @__PURE__ */ ((ApplicationModal2) => {
  ApplicationModal2[ApplicationModal2["ADDRESS_CLAIM"] = 0] = "ADDRESS_CLAIM";
  ApplicationModal2[ApplicationModal2["BLOCKED_ACCOUNT"] = 1] = "BLOCKED_ACCOUNT";
  ApplicationModal2[ApplicationModal2["CLAIM_POPUP"] = 2] = "CLAIM_POPUP";
  ApplicationModal2[ApplicationModal2["DELEGATE"] = 3] = "DELEGATE";
  ApplicationModal2[ApplicationModal2["EXECUTE"] = 4] = "EXECUTE";
  ApplicationModal2[ApplicationModal2["FEATURE_FLAGS"] = 5] = "FEATURE_FLAGS";
  ApplicationModal2[ApplicationModal2["FIAT_ONRAMP"] = 6] = "FIAT_ONRAMP";
  ApplicationModal2[ApplicationModal2["RECEIVE_CRYPTO"] = 7] = "RECEIVE_CRYPTO";
  ApplicationModal2[ApplicationModal2["RECEIVE_CRYPTO_QR"] = 8] = "RECEIVE_CRYPTO_QR";
  ApplicationModal2[ApplicationModal2["MENU"] = 9] = "MENU";
  ApplicationModal2[ApplicationModal2["METAMASK_CONNECTION_ERROR"] = 10] = "METAMASK_CONNECTION_ERROR";
  ApplicationModal2[ApplicationModal2["NETWORK_SELECTOR"] = 11] = "NETWORK_SELECTOR";
  ApplicationModal2[ApplicationModal2["PRIVACY_POLICY"] = 12] = "PRIVACY_POLICY";
  ApplicationModal2[ApplicationModal2["QUEUE"] = 13] = "QUEUE";
  ApplicationModal2[ApplicationModal2["SELF_CLAIM"] = 14] = "SELF_CLAIM";
  ApplicationModal2[ApplicationModal2["SETTINGS"] = 15] = "SETTINGS";
  ApplicationModal2[ApplicationModal2["SHARE"] = 16] = "SHARE";
  ApplicationModal2[ApplicationModal2["TAX_SERVICE"] = 17] = "TAX_SERVICE";
  ApplicationModal2[ApplicationModal2["VOTE"] = 18] = "VOTE";
  ApplicationModal2[ApplicationModal2["UK_DISCLAIMER"] = 19] = "UK_DISCLAIMER";
  ApplicationModal2[ApplicationModal2["GET_THE_APP"] = 20] = "GET_THE_APP";
  return ApplicationModal2;
})(ApplicationModal || {});
const initialState = {
  fiatOnramp: { available: false, availabilityChecked: false },
  chainId: null,
  openModal: null,
  popupList: [],
  suppressedPopups: []
};
const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setFiatOnrampAvailability(state, { payload: available }) {
      state.fiatOnramp = { available, availabilityChecked: true };
    },
    updateChainId(state, action) {
      const { chainId } = action.payload;
      state.chainId = chainId;
    },
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
    addPopup(state, { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }) {
      key = key || nanoid();
      state.popupList = [
        ...state.popupList.filter((popup) => popup.key !== key),
        {
          key,
          show: !state.suppressedPopups.includes(content.type),
          content,
          removeAfterMs
        }
      ];
    },
    removePopup(state, { payload: { key } }) {
      state.popupList = state.popupList.map((popup) => {
        if (popup.key === key) {
          popup.show = false;
        }
        return popup;
      });
    },
    addSuppressedPopups(state, { payload: { popupTypes } }) {
      state.suppressedPopups = Array.from(/* @__PURE__ */ new Set([...state.suppressedPopups, ...popupTypes]));
    },
    removeSuppressedPopups(state, { payload: { popupTypes } }) {
      state.suppressedPopups = state.suppressedPopups.filter((type) => !popupTypes.includes(type));
    }
  }
});
export const {
  updateChainId,
  setFiatOnrampAvailability,
  setOpenModal,
  addPopup,
  removePopup,
  addSuppressedPopups,
  removeSuppressedPopups
} = applicationSlice.actions;
export default applicationSlice.reducer;
