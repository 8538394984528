import { Checkbox } from "ui/src/components/checkbox/Checkbox";
import { Flex } from "ui/src/components/layout";
import { Text } from "ui/src/components/text";
import { TouchableArea } from "ui/src/components/touchable";
export function LabeledCheckbox({ text, checked, variant, onCheckPressed }) {
  const onPress = () => {
    onCheckPressed?.(checked);
  };
  const textElement = typeof text === "string" ? <Text $short={{ variant: "buttonLabel4" }} variant="subheading2">{text}</Text> : text;
  return <TouchableArea onPress={onPress}><Flex row alignItems="center" gap="$spacing12" px="$spacing4">
    <Checkbox checked={checked} variant={variant} onPress={onPress} />
    {text && <Flex shrink>{textElement}</Flex>}
  </Flex></TouchableArea>;
}
