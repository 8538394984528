"use strict";
import AssetLogo from "components/Logo/AssetLogo";
export default function CurrencyLogo(props) {
  return <AssetLogo
    currency={props.currency}
    isNative={props.currency?.isNative}
    chainId={props.currency?.chainId}
    address={props.currency?.wrapped.address}
    symbol={props.symbol ?? props.currency?.symbol}
    primaryImg={props.currency?.logoURI}
    {...props}
  />;
}
