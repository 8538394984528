"use strict";
import {
  TokenStandard
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
const HIDE_SMALL_USD_BALANCES_THRESHOLD = 1;
export function splitHiddenTokens(tokenBalances, { hideSmallBalances = true, hideSpam = true } = {}) {
  const visibleTokens = [];
  const hiddenTokens = [];
  for (const tokenBalance of tokenBalances) {
    if (!tokenBalance) {
      continue;
    }
    const isSpam = tokenBalance.tokenProjectMarket?.tokenProject?.isSpam;
    if (hideSpam && isSpam || hideSmallBalances && isNegligibleBalance(tokenBalance)) {
      hiddenTokens.push(tokenBalance);
    } else {
      visibleTokens.push(tokenBalance);
    }
  }
  return { visibleTokens, hiddenTokens };
}
function isNegligibleBalance({ denominatedValue, token }) {
  return denominatedValue?.value !== void 0 && // if undefined we keep visible (see WEB-1940)
  token?.standard !== TokenStandard.Native && // always show native token balances
  denominatedValue?.value < HIDE_SMALL_USD_BALANCES_THRESHOLD;
}
