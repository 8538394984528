"use strict";
import { NetworkStatus } from "@apollo/client";
import { CurrencyAmount, Price, TradeType } from "@uniswap/sdk-core";
import {
  chainIdToBackendChain,
  useIsSupportedChainId,
  useSupportedChainId
} from "constants/chains";
import { nativeOnChain } from "constants/tokens";
import { PollingInterval } from "graphql/data/util";
import useIsWindowVisible from "hooks/useIsWindowVisible";
import useStablecoinPrice from "hooks/useStablecoinPrice";
import { useMemo } from "react";
import { ClassicTrade, INTERNAL_ROUTER_PREFERENCE_PRICE, TradeState } from "state/routing/types";
import { useRoutingAPITrade } from "state/routing/useRoutingAPITrade";
import { Chain, useTokenSpotPriceQuery } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { UniverseChainId } from "uniswap/src/types/chains";
import { getNativeTokenDBAddress } from "utils/nativeTokens";
function getEthAmountOut(chainId) {
  return CurrencyAmount.fromRawAmount(nativeOnChain(chainId), chainId === UniverseChainId.Mainnet ? 5e19 : 1e19);
}
function useETHPrice(currency) {
  const chainId = currency?.chainId;
  const isSupportedChain = useIsSupportedChainId(chainId);
  const isSupported = isSupportedChain && currency;
  const amountOut = isSupported ? getEthAmountOut(chainId) : void 0;
  const { trade, state } = useRoutingAPITrade(
    !isSupported,
    TradeType.EXACT_OUTPUT,
    amountOut,
    currency,
    INTERNAL_ROUTER_PREFERENCE_PRICE
  );
  return useMemo(() => {
    if (!isSupported) {
      return { data: void 0, isLoading: false };
    }
    if (currency?.wrapped.equals(nativeOnChain(chainId).wrapped)) {
      return {
        data: new Price(currency, currency, "1", "1"),
        isLoading: false
      };
    }
    if (!trade || state === TradeState.LOADING) {
      return { data: void 0, isLoading: state === TradeState.LOADING };
    }
    if (trade && trade instanceof ClassicTrade) {
      const { numerator, denominator } = trade.routes[0].midPrice;
      const price = new Price(currency, nativeOnChain(chainId), denominator, numerator);
      return { data: price, isLoading: false };
    }
    return { data: void 0, isLoading: false };
  }, [chainId, currency, isSupported, state, trade]);
}
export function useUSDPrice(currencyAmount, prefetchCurrency) {
  const currency = currencyAmount?.currency ?? prefetchCurrency;
  const chainId = useSupportedChainId(currency?.chainId);
  const chain = chainIdToBackendChain({ chainId });
  const isWindowVisible = useIsWindowVisible();
  const { data: tokenEthPrice, isLoading: isTokenEthPriceLoading } = useETHPrice(currency);
  const isTokenEthPriced = Boolean(tokenEthPrice || isTokenEthPriceLoading);
  const { data, networkStatus } = useTokenSpotPriceQuery({
    variables: { chain: chain ?? Chain.Ethereum, address: getNativeTokenDBAddress(chain ?? Chain.Ethereum) },
    skip: !isTokenEthPriced || !isWindowVisible,
    pollInterval: PollingInterval.Normal,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first"
  });
  const { price: stablecoinPrice } = useStablecoinPrice(isTokenEthPriced ? void 0 : currency);
  return useMemo(() => {
    if (!currencyAmount) {
      return { data: void 0, isLoading: false };
    } else if (stablecoinPrice) {
      return { data: parseFloat(stablecoinPrice.quote(currencyAmount).toSignificant()), isLoading: false };
    } else {
      const ethUSDPrice = data?.token?.project?.markets?.[0]?.price?.value;
      if (ethUSDPrice && tokenEthPrice) {
        return { data: parseFloat(tokenEthPrice.quote(currencyAmount).toExact()) * ethUSDPrice, isLoading: false };
      } else {
        return { data: void 0, isLoading: isTokenEthPriceLoading || networkStatus === NetworkStatus.loading };
      }
    }
  }, [
    currencyAmount,
    data?.token?.project?.markets,
    tokenEthPrice,
    isTokenEthPriceLoading,
    networkStatus,
    stablecoinPrice
  ]);
}
