"use strict";
import SizingImage from "assets/images/sizingImage.png";
import { ASSET_PAGE_SIZE } from "graphql/data/nft/Asset";
import { Box } from "nft/components/Box";
import { Row } from "nft/components/Flex";
import * as styles from "nft/components/collection/CollectionAssetLoading.css";
import { loadingAsset } from "nft/css/loading.css";
const CollectionAssetLoading = ({ height }) => {
  return <Box as="div" className={styles.collectionAssetLoading}>
    <Box as="div" position="relative" width="full" style={{ height }}>
      <Box as="div" className={styles.collectionAssetsImageLoading} />
      <Box as="img" width="full" opacity="0" src={SizingImage} draggable={false} />
    </Box>
    <Row justifyContent="space-between" marginTop="12" paddingLeft="12" paddingRight="12"><Box as="div" className={loadingAsset} height="12" width="120" /></Row>
    <Row justifyContent="space-between" marginTop="12" paddingLeft="12" paddingRight="12"><Box as="div" className={loadingAsset} height="16" width="80" /></Row>
  </Box>;
};
export const LoadingAssets = ({ count, height }) => <>{Array.from(Array(count ?? ASSET_PAGE_SIZE), (_, index) => <CollectionAssetLoading key={index} height={height} />)}</>;
