"use strict";
import { SearchResultType } from "uniswap/src/features/search/SearchResult";
import { searchResultId } from "uniswap/src/features/search/searchHistorySlice";
import { tokenAddressOrNativeAddress } from "uniswap/src/features/search/utils";
export const migration17 = (state) => {
  if (!state) {
    return;
  }
  const newState = { ...state };
  newState.searchHistory.results.forEach((result) => {
    if (result.type === SearchResultType.Token && result.address) {
      const nativeAddress = tokenAddressOrNativeAddress(result.address, result.chainId);
      if (result.address !== nativeAddress) {
        result.address = nativeAddress;
        result.searchId = searchResultId(result);
      }
    }
  });
  const dedupedSearchHistory = newState.searchHistory.results.filter(
    (result, index, self) => self.findIndex((t) => t.searchId === result.searchId) === index
  );
  newState.searchHistory.results = dedupedSearchHistory;
  return { ...newState, _persist: { ...state._persist, version: 17 } };
};
