"use strict";
import { Hero } from "pages/Landing/sections/Hero";
import { Suspense, lazy, memo, useRef } from "react";
import { NAV_HEIGHT } from "theme";
import { Flex, styled } from "ui/src";
const Fold = lazy(() => import(
  /* webpackPreload: true */
  "./Fold"
));
const Rive = lazy(() => import(
  /* webpackPreload: true */
  "setupRive"
));
const Grain = styled(Flex, {
  position: "absolute",
  inset: 0,
  background: "url(/images/noise-color.png)",
  opacity: 0.018,
  zIndex: 0
});
function LandingV2({ transition }) {
  const scrollAnchor = useRef(null);
  const scrollToRef = () => {
    if (scrollAnchor.current) {
      window.scrollTo({
        top: scrollAnchor.current.offsetTop - 120,
        behavior: "smooth"
      });
    }
  };
  return <Flex position="relative" alignItems="center" mt={-NAV_HEIGHT} minWidth="100vw" data-testid="landing-page">
    <Grain />
    <Hero scrollToRef={scrollToRef} transition={transition} />
    <Suspense>
      <Rive />
      <Fold ref={scrollAnchor} />
    </Suspense>
  </Flex>;
}
export default memo(LandingV2);
