"use strict";
import { TokenFromList } from "state/lists/tokenFromList";
const mapCache = typeof WeakMap !== "undefined" ? /* @__PURE__ */ new WeakMap() : null;
export function tokensToChainTokenMap(tokens) {
  const cached = mapCache?.get(tokens);
  if (cached) {
    return cached;
  }
  const [list, infos] = Array.isArray(tokens) ? [void 0, tokens] : [tokens, tokens.tokens];
  const map = infos.reduce((map2, info) => {
    try {
      const token = new TokenFromList(info, list);
      if (map2[token.chainId]?.[token.address] !== void 0) {
        return map2;
      }
      if (!map2[token.chainId]) {
        map2[token.chainId] = {};
      }
      map2[token.chainId][token.address] = { token, list };
      return map2;
    } catch {
      return map2;
    }
  }, {});
  mapCache?.set(tokens, map);
  return map;
}
