"use strict";
import { Token } from "@uniswap/sdk-core";
import { Interface } from "ethers/lib/utils";
import ERC20_ABI from "uniswap/src/abis/erc20.json";
import { isAddress } from "utilities/src/addresses";
import { logger } from "utilities/src/logger/logger";
import { DEFAULT_ERC20_DECIMALS } from "utilities/src/tokens/constants";
import { arrayToSlices } from "utils/arrays";
import { buildCurrencyKey, currencyKey } from "utils/currencyKey";
export const DEFAULT_GAS_LIMIT = 1e6;
const Erc20 = new Interface(ERC20_ABI);
const Erc20Bytes32 = new Interface(ERC20_ABI);
async function fetchChunk(multicall, chunk) {
  try {
    return (await multicall.callStatic.multicall(chunk)).returnData;
  } catch (error) {
    if (error.code === -32603 || error.message?.indexOf("execution ran out of gas") !== -1) {
      if (chunk.length > 1) {
        const half = Math.floor(chunk.length / 2);
        return Promise.all([
          fetchChunk(multicall, chunk.slice(0, half)),
          fetchChunk(multicall, chunk.slice(half, chunk.length))
        ]).then(([c0, c1]) => [...c0, ...c1]);
      }
    }
    logger.debug("getTokensAsync", "fetchChunk", "Error fetching chunk", { error, extra: { chunk } });
    throw error;
  }
}
function tryParseToken(address, chainId, data) {
  try {
    const [nameData, symbolData, decimalsData, nameDataBytes32, symbolDataBytes32] = data;
    const name = nameData.success ? Erc20.decodeFunctionResult("name", nameData.returnData)[0] : nameDataBytes32.success ? Erc20Bytes32.decodeFunctionResult("name", nameDataBytes32.returnData)[0] : void 0;
    const symbol = symbolData.success ? Erc20.decodeFunctionResult("symbol", symbolData.returnData)[0] : symbolDataBytes32.success ? Erc20Bytes32.decodeFunctionResult("symbol", symbolDataBytes32.returnData)[0] : void 0;
    const decimals = decimalsData.success ? parseInt(decimalsData.returnData) : DEFAULT_ERC20_DECIMALS;
    return new Token(chainId, address, decimals, symbol, name);
  } catch (error) {
    logger.debug("getTokensAsync", "tryParseToken", "Failed to parse token", { error, address, chainId });
    return void 0;
  }
}
function parseTokens(addresses, chainId, returnData) {
  const tokenDataSlices = arrayToSlices(returnData, 5);
  return tokenDataSlices.reduce((acc, slice, index) => {
    const parsedToken = tryParseToken(addresses[index], chainId, slice);
    if (parsedToken) {
      acc[parsedToken.address] = parsedToken;
    }
    return acc;
  }, {});
}
const createCalls = (target, callData) => callData.map((callData2) => ({ target, callData: callData2, gasLimit: DEFAULT_GAS_LIMIT }));
function createCallsForToken(address) {
  return createCalls(address, [
    Erc20.encodeFunctionData("name"),
    Erc20.encodeFunctionData("symbol"),
    Erc20.encodeFunctionData("decimals"),
    Erc20Bytes32.encodeFunctionData("name"),
    Erc20Bytes32.encodeFunctionData("symbol")
  ]);
}
const TokenPromiseCache = {};
export async function getTokensAsync(addresses, chainId, multicall) {
  if (addresses.length === 0) {
    return {};
  }
  const formattedAddresses = [];
  const calls = [];
  const previouslyCalledTokens = [];
  addresses.forEach((tokenAddress) => {
    const key = buildCurrencyKey(chainId, tokenAddress);
    const previousCall = TokenPromiseCache[key];
    if (previousCall !== void 0) {
      previouslyCalledTokens.push(previousCall);
    } else {
      const formattedAddress = isAddress(tokenAddress);
      if (!formattedAddress) {
        return;
      }
      formattedAddresses.push(formattedAddress);
      calls.push(...createCallsForToken(formattedAddress));
    }
  });
  const calledTokens = fetchChunk(multicall, calls).then((returnData) => parseTokens(addresses, chainId, returnData));
  formattedAddresses.forEach(
    (address) => TokenPromiseCache[buildCurrencyKey(chainId, address)] = calledTokens.then((tokenMap2) => tokenMap2[address])
  );
  const tokenMap = await calledTokens;
  const resolvedPreviousTokens = await Promise.all(previouslyCalledTokens);
  resolvedPreviousTokens.forEach((token) => token && (tokenMap[currencyKey(token)] = token));
  return tokenMap;
}
