export function getKeys(obj) {
  return Object.keys(obj);
}
export function flattenObjectOfObjects(obj) {
  return Object.values(obj).map((o) => Object.values(o)).flat();
}
export function unnestObject(ob) {
  const toReturn = {};
  for (const i in ob) {
    if (!Object.prototype.hasOwnProperty.call(ob, i)) {
      continue;
    }
    if (typeof ob[i] !== "object" || ob[i] === null) {
      toReturn[i] = ob[i];
      continue;
    }
    const flatObject = unnestObject(ob[i]);
    for (const x in flatObject) {
      if (!Object.prototype.hasOwnProperty.call(flatObject, x)) {
        continue;
      }
      const property = flatObject[x];
      if (property === void 0) {
        continue;
      }
      toReturn[i + "." + x] = property;
    }
  }
  return toReturn;
}
export function getAllKeysOfNestedObject(obj, prefix = "") {
  const keys = Object.keys(obj);
  if (!keys.length && prefix !== "") {
    return [prefix.slice(0, -1)];
  }
  return keys.reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return [...res];
    }
    if (typeof obj[el] === "object" && obj[el] !== null) {
      return [
        ...res,
        ...getAllKeysOfNestedObject(obj[el], prefix + el + ".")
      ];
    }
    return [...res, prefix + el];
  }, []);
}
