"use strict";
import { InterfaceEventName } from "@uniswap/analytics-events";
import { ConfirmModalState } from "components/ConfirmSwapModal";
import { PendingModalError } from "components/ConfirmSwapModal/Error";
import { Field, RESET_APPROVAL_TOKENS } from "components/swap/constants";
import { useAccount } from "hooks/useAccount";
import { useMaxAmountIn } from "hooks/useMaxAmountIn";
import { AllowanceState } from "hooks/usePermit2Allowance";
import usePrevious from "hooks/usePrevious";
import useSelectChain from "hooks/useSelectChain";
import useWrapCallback from "hooks/useWrapCallback";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { getPriceUpdateBasisPoints } from "lib/utils/analytics";
import { useCallback, useEffect, useState } from "react";
import { isUniswapXTrade } from "state/routing/utils";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { useIsTransactionConfirmed } from "state/transactions/hooks";
import invariant from "tiny-invariant";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { logger } from "utilities/src/logger/logger";
import { useTrace } from "utilities/src/telemetry/trace/TraceContext";
import { NumberType, useFormatter } from "utils/formatNumbers";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
import { tradeMeaningfullyDiffers } from "utils/tradeMeaningFullyDiffer";
export function useConfirmModalState({
  trade,
  originalTrade,
  allowedSlippage,
  onSwap,
  allowance,
  onCurrencySelection
}) {
  const [confirmModalState, setConfirmModalState] = useState(ConfirmModalState.REVIEWING);
  const [approvalError, setApprovalError] = useState();
  const [pendingModalSteps, setPendingModalSteps] = useState([]);
  const { formatCurrencyAmount } = useFormatter();
  const account = useAccount();
  const { chainId } = useSwapAndLimitContext();
  const generateRequiredSteps = useCallback(() => {
    const steps = [];
    if (isUniswapXTrade(trade) && trade.wrapInfo.needsWrap) {
      steps.push(ConfirmModalState.WRAPPING);
    }
    if (allowance.state === AllowanceState.REQUIRED && allowance.needsSetupApproval && RESET_APPROVAL_TOKENS.some((token) => token.equals(allowance.token)) && allowance.allowedAmount.greaterThan(0)) {
      steps.push(ConfirmModalState.RESETTING_TOKEN_ALLOWANCE);
    }
    if (allowance.state === AllowanceState.REQUIRED && allowance.needsSetupApproval) {
      steps.push(ConfirmModalState.APPROVING_TOKEN);
    }
    if (allowance.state === AllowanceState.REQUIRED && allowance.needsPermitSignature) {
      steps.push(ConfirmModalState.PERMITTING);
    }
    steps.push(ConfirmModalState.PENDING_CONFIRMATION);
    return steps;
  }, [allowance, trade]);
  const trace = useTrace();
  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage);
  const nativeCurrency = useNativeCurrency(chainId);
  const [wrapTxHash, setWrapTxHash] = useState();
  const { execute: onWrap } = useWrapCallback(
    nativeCurrency,
    trade.inputAmount.currency,
    formatCurrencyAmount({
      amount: trade.inputAmount,
      type: NumberType.SwapTradeAmount
    })
  );
  const wrapConfirmed = useIsTransactionConfirmed(wrapTxHash);
  const prevWrapConfirmed = usePrevious(wrapConfirmed);
  const catchUserReject = useCallback(
    async (e, errorType) => {
      setConfirmModalState(ConfirmModalState.REVIEWING);
      if (didUserReject(e)) {
        return;
      }
      logger.warn("useConfirmModalState", "catchUserReject", "Failed to wrap", { error: e, trade });
      setApprovalError(errorType);
    },
    [trade]
  );
  const selectChain = useSelectChain();
  const performStep = useCallback(
    async (step) => {
      switch (step) {
        case ConfirmModalState.WRAPPING:
          setConfirmModalState(ConfirmModalState.WRAPPING);
          onWrap?.().then((wrapTxHash2) => {
            setWrapTxHash(wrapTxHash2);
            onCurrencySelection(Field.INPUT, trade.inputAmount.currency);
            sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
              chain_id: chainId,
              token_symbol: maximumAmountIn?.currency.symbol,
              token_address: maximumAmountIn?.currency.address,
              ...trade,
              ...trace
            });
          }).catch((e) => catchUserReject(e, PendingModalError.WRAP_ERROR));
          break;
        case ConfirmModalState.RESETTING_TOKEN_ALLOWANCE:
          setConfirmModalState(ConfirmModalState.RESETTING_TOKEN_ALLOWANCE);
          invariant(allowance.state === AllowanceState.REQUIRED, "Allowance should be required");
          allowance.revoke().catch((e) => catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR));
          break;
        case ConfirmModalState.APPROVING_TOKEN:
          setConfirmModalState(ConfirmModalState.APPROVING_TOKEN);
          invariant(allowance.state === AllowanceState.REQUIRED, "Allowance should be required");
          allowance.approve().catch((e) => catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR));
          break;
        case ConfirmModalState.PERMITTING:
          setConfirmModalState(ConfirmModalState.PERMITTING);
          invariant(allowance.state === AllowanceState.REQUIRED, "Allowance should be required");
          allowance.permit().catch((e) => catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR));
          break;
        case ConfirmModalState.PENDING_CONFIRMATION:
          setConfirmModalState(ConfirmModalState.PENDING_CONFIRMATION);
          try {
            onSwap();
          } catch (e) {
            catchUserReject(e, PendingModalError.CONFIRMATION_ERROR);
          }
          break;
        default:
          setConfirmModalState(ConfirmModalState.REVIEWING);
          break;
      }
    },
    [
      onWrap,
      allowance,
      onCurrencySelection,
      trade,
      chainId,
      maximumAmountIn?.currency.symbol,
      maximumAmountIn?.currency.address,
      trace,
      catchUserReject,
      onSwap
    ]
  );
  const startSwapFlow = useCallback(async () => {
    if (chainId && chainId !== account.chainId) {
      const switchChainResult = await selectChain(chainId);
      if (!switchChainResult) {
        return;
      }
    }
    const steps = generateRequiredSteps();
    setPendingModalSteps(steps);
    performStep(steps[0]);
  }, [account.chainId, chainId, generateRequiredSteps, performStep, selectChain]);
  const previousSetupApprovalNeeded = usePrevious(
    allowance.state === AllowanceState.REQUIRED ? allowance.needsSetupApproval : void 0
  );
  useEffect(() => {
    if (wrapConfirmed && !prevWrapConfirmed) {
      performStep(pendingModalSteps[1]);
    }
  }, [pendingModalSteps, performStep, prevWrapConfirmed, wrapConfirmed]);
  useEffect(() => {
    if (allowance.state === AllowanceState.REQUIRED && allowance.needsPermitSignature && // If the token approval switched from missing to fulfilled, trigger the next step (permit2 signature).
    !allowance.needsSetupApproval && previousSetupApprovalNeeded) {
      performStep(ConfirmModalState.PERMITTING);
    }
  }, [allowance, performStep, previousSetupApprovalNeeded]);
  const previousRevocationPending = usePrevious(
    allowance.state === AllowanceState.REQUIRED && allowance.isRevocationPending
  );
  useEffect(() => {
    if (allowance.state === AllowanceState.REQUIRED && previousRevocationPending && !allowance.isRevocationPending) {
      performStep(ConfirmModalState.APPROVING_TOKEN);
    }
  }, [allowance, performStep, previousRevocationPending]);
  function isInApprovalPhase(confirmModalState2) {
    return confirmModalState2 === ConfirmModalState.RESETTING_TOKEN_ALLOWANCE || confirmModalState2 === ConfirmModalState.APPROVING_TOKEN || confirmModalState2 === ConfirmModalState.PERMITTING;
  }
  const doesTradeDiffer = originalTrade && tradeMeaningfullyDiffers(trade, originalTrade, allowedSlippage);
  useEffect(() => {
    if (isInApprovalPhase(confirmModalState) && allowance.state === AllowanceState.ALLOWED) {
      if (doesTradeDiffer) {
        setConfirmModalState(ConfirmModalState.REVIEWING);
        return;
      }
      performStep(ConfirmModalState.PENDING_CONFIRMATION);
    }
  }, [allowance, confirmModalState, doesTradeDiffer, performStep]);
  const resetToReviewScreen = () => {
    setConfirmModalState(ConfirmModalState.REVIEWING);
  };
  const onCancel = () => {
    setConfirmModalState(ConfirmModalState.REVIEWING);
    setApprovalError(void 0);
  };
  const [lastExecutionPrice, setLastExecutionPrice] = useState(trade?.executionPrice);
  const [priceUpdate, setPriceUpdate] = useState();
  useEffect(() => {
    if (lastExecutionPrice && !trade.executionPrice.equalTo(lastExecutionPrice)) {
      setPriceUpdate(getPriceUpdateBasisPoints(lastExecutionPrice, trade.executionPrice));
      setLastExecutionPrice(trade.executionPrice);
    }
  }, [lastExecutionPrice, setLastExecutionPrice, trade]);
  return {
    startSwapFlow,
    resetToReviewScreen,
    onCancel,
    confirmModalState,
    doesTradeDiffer,
    approvalError,
    pendingModalSteps,
    priceUpdate,
    wrapTxHash
  };
}
