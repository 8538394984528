"use strict";
import { CurrencyRow } from "components/SearchModal/CurrencyList";
import { scrollbarStyle } from "components/SearchModal/CurrencyList/index.css";
import { HeaderContent } from "pages/Swap/Buy/CountryListModal";
import { ContentWrapper } from "pages/Swap/Buy/shared";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { CloseIcon } from "theme/components";
import { AdaptiveWebModal, Flex } from "ui/src";
import { Text } from "ui/src/components/text/Text";
import { useTranslation } from "uniswap/src/i18n";
const ROW_ITEM_SIZE = 56;
export function FiatOnRampCurrencyModal({
  isOpen,
  onDismiss,
  currencies,
  selectedCurrency,
  onSelectCurrency
}) {
  const { t } = useTranslation();
  return <AdaptiveWebModal
    width={420}
    maxWidth="90vw"
    height="90vh"
    maxHeight={700}
    isOpen={isOpen}
    onClose={onDismiss}
    p={0}
  ><ContentWrapper>
    <HeaderContent><Flex row justifyContent="space-between">
      <Text variant="subheading1">{t("common.selectToken.label")}</Text>
      <CloseIcon data-testid="FiatOnRampCurrencyModal-close" onClick={onDismiss} />
    </Flex></HeaderContent>
    <Flex grow><AutoSizer disableWidth>{({ height }) => <div data-testid="for-currency-list-wrapper"><FixedSizeList
      className={scrollbarStyle}
      height={height}
      width="100%"
      itemData={currencies}
      itemCount={currencies.length}
      itemSize={ROW_ITEM_SIZE}
      itemKey={(index, data) => data[index]?.meldCurrencyCode ?? index}
    >{({ style, data, index }) => {
      const currencyInfo = data[index].currencyInfo;
      if (!currencyInfo) {
        return null;
      }
      return <CurrencyRow
        style={style}
        currency={currencyInfo.currency}
        onSelect={() => {
          onSelectCurrency(data[index]);
          onDismiss();
        }}
        isSelected={selectedCurrency?.meldCurrencyCode === data[index].meldCurrencyCode}
        eventProperties={{}}
        otherSelected={false}
      />;
    }}</FixedSizeList></div>}</AutoSizer></Flex>
  </ContentWrapper></AdaptiveWebModal>;
}
