"use strict";
import { Currency } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export function getFiatCurrencyName(t, currency) {
  const currencyToCurrencyName = {
    [Currency.Aud]: t("currency.aud"),
    [Currency.Brl]: t("currency.brl"),
    [Currency.Cad]: t("currency.cad"),
    [Currency.Cny]: t("currency.cny"),
    [Currency.Eur]: t("currency.eur"),
    [Currency.Gbp]: t("currency.gbp"),
    [Currency.Hkd]: t("currency.hkd"),
    [Currency.Idr]: t("currency.idr"),
    [Currency.Inr]: t("currency.inr"),
    [Currency.Jpy]: t("currency.jpy"),
    [Currency.Krw]: t("currency.krw"),
    [Currency.Ngn]: t("currency.ngn"),
    [Currency.Pkr]: t("currency.pkr"),
    [Currency.Rub]: t("currency.rub"),
    [Currency.Sgd]: t("currency.sgd"),
    [Currency.Thb]: t("currency.thb"),
    [Currency.Try]: t("currency.try"),
    [Currency.Uah]: t("currency.uah"),
    [Currency.Usd]: t("currency.usd"),
    [Currency.Vnd]: t("currency.vnd")
  };
  const currencyToGlobalSymbol = {
    [Currency.Aud]: "$",
    [Currency.Brl]: "R$",
    [Currency.Cad]: "$",
    [Currency.Cny]: "\xA5",
    [Currency.Eur]: "\u20AC",
    [Currency.Gbp]: "\xA3",
    [Currency.Hkd]: "$",
    [Currency.Idr]: "Rp",
    [Currency.Inr]: "\u20B9",
    [Currency.Jpy]: "\xA5",
    [Currency.Krw]: "\u20A9",
    [Currency.Ngn]: "\u20A6",
    [Currency.Pkr]: "Rs",
    [Currency.Rub]: "\u20BD",
    [Currency.Sgd]: "$",
    [Currency.Thb]: "\u0E3F",
    [Currency.Try]: "\u20BA",
    [Currency.Uah]: "\u20B4",
    [Currency.Usd]: "$",
    [Currency.Vnd]: "\u20AB"
  };
  const code = currency.toUpperCase();
  const symbol = currencyToGlobalSymbol[currency];
  return { name: currencyToCurrencyName[currency], shortName: `${code} (${symbol})` };
}
