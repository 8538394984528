"use strict";
const keys = /* @__PURE__ */ new Map();
export const getFetchPolicyForKey = (key, expirationMs) => {
  const lastFetchTimestamp = keys.get(key);
  const diffFromNow = lastFetchTimestamp ? Date.now() - lastFetchTimestamp : Number.MAX_SAFE_INTEGER;
  let fetchPolicy = "cache-first";
  if (diffFromNow > expirationMs) {
    keys.set(key, Date.now());
    fetchPolicy = "network-only";
  }
  return fetchPolicy;
};
