"use strict";
import { Input } from "components/NumericalInput";
import Row from "components/Row";
import styled, { css } from "lib/styled-components";
import { useLayoutEffect, useState } from "react";
const NumericalInputFontStyle = css`
  text-align: left;
  font-size: 70px;
  font-weight: 500;
  line-height: 60px;
`;
export const NumericalInputWrapper = styled(Row)`
  position: relative;
  max-width: 100%;
  width: max-content;
`;
export const StyledNumericalInput = styled(Input)`
  max-height: 84px;
  max-width: 100%;
  width: ${({ $width }) => `${$width ?? 43}px`}; // this value is from the size of a 0 which is the default value
  ${NumericalInputFontStyle}

  ::placeholder {
    opacity: 1;
  }
`;
export const NumericalInputMimic = styled.span`
  position: absolute;
  visibility: hidden;
  bottom: 0px;
  right: 0px;
  ${NumericalInputFontStyle}
`;
export const NumericalInputSymbolContainer = styled.span`
  user-select: none;
  ${NumericalInputFontStyle}
  ${({ showPlaceholder }) => showPlaceholder && css`
      color: ${({ theme }) => theme.neutral3};
    `}
`;
export function useWidthAdjustedDisplayValue(displayValue) {
  const [postWidthAdjustedDisplayValue, setPostWidthAdjustedDisplayValue] = useState("");
  useLayoutEffect(() => {
    requestAnimationFrame(() => setPostWidthAdjustedDisplayValue(displayValue));
  }, [displayValue]);
  return postWidthAdjustedDisplayValue;
}
