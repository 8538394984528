"use strict";
import { useMainnetBlockNumber } from "lib/hooks/useBlockNumber";
import { useCallContext } from "lib/hooks/useCallContext";
import multicall from "lib/state/multicall";
import { UniverseChainId } from "uniswap/src/types/chains";
export { NEVER_RELOAD } from "@uniswap/redux-multicall";
export function useMultipleContractSingleData(...args) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useMultipleContractSingleData(chainId, latestBlock, ...args);
}
export function useSingleCallResult(...args) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useSingleCallResult(chainId, latestBlock, ...args);
}
export function useMainnetSingleCallResult(...args) {
  const latestMainnetBlock = useMainnetBlockNumber();
  return multicall.hooks.useSingleCallResult(UniverseChainId.Mainnet, latestMainnetBlock, ...args);
}
export function useSingleContractMultipleData(...args) {
  const { chainId, latestBlock } = useCallContext();
  return multicall.hooks.useSingleContractMultipleData(chainId, latestBlock, ...args);
}
