"use strict";
import { LoaderV3 } from "components/Icons/LoadingSpinner";
import ENSAvatarIcon from "components/Identicon/ENSAvatarIcon";
import { UniTagProfilePicture } from "components/UniTag/UniTagProfilePicture";
import useENSAvatar from "hooks/useENSAvatar";
import styled from "lib/styled-components";
import { fadeInAnimation } from "theme/components/FadePresence";
import { Unicon } from "ui/src";
import { useUnitagByAddress } from "uniswap/src/features/unitags/hooks";
export var IdenticonType = /* @__PURE__ */ ((IdenticonType2) => {
  IdenticonType2["LOADING"] = "loading";
  IdenticonType2["UNITAG_PROFILE_PICTURE"] = "unitagProfilePicture";
  IdenticonType2["ENS_AVATAR"] = "ensAvatar";
  IdenticonType2["UNICON"] = "unicon";
  return IdenticonType2;
})(IdenticonType || {});
export function useIdenticonType(account) {
  const { unitag, loading: unitagLoading } = useUnitagByAddress(account);
  const { avatar, loading: ensAvatarLoading } = useENSAvatar(account);
  if (!account) {
    return void 0;
  }
  if (unitagLoading) {
    return "loading" /* LOADING */;
  } else if (unitag?.metadata?.avatar) {
    return "unitagProfilePicture" /* UNITAG_PROFILE_PICTURE */;
  } else if (avatar) {
    return "ensAvatar" /* ENS_AVATAR */;
  } else if (ensAvatarLoading) {
    return "loading" /* LOADING */;
  } else {
    return "unicon" /* UNICON */;
  }
}
const FadeInContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${fadeInAnimation}
`;
export default function Identicon({ account, size }) {
  const identiconType = useIdenticonType(account);
  if (!account) {
    return null;
  }
  switch (identiconType) {
    case "loading" /* LOADING */:
      return <LoaderV3 size={size + "px"} data-testid="IdenticonLoader" />;
    case "unitagProfilePicture" /* UNITAG_PROFILE_PICTURE */:
      return <FadeInContainer><UniTagProfilePicture account={account} size={size} /></FadeInContainer>;
    case "ensAvatar" /* ENS_AVATAR */:
      return <FadeInContainer><ENSAvatarIcon account={account} size={size} /></FadeInContainer>;
    case "unicon" /* UNICON */:
      return <FadeInContainer><Unicon address={account} size={size} /></FadeInContainer>;
    default:
      return null;
  }
}
