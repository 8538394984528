"use strict";
import {
  formatPercentInBasisPointsNumber,
  formatPercentNumber,
  formatToDecimal,
  getDurationUntilTimestampSeconds,
  getTokenAddress
} from "lib/utils/analytics";
import { TradeFillType } from "state/routing/types";
import { isClassicTrade, isUniswapXTradeType } from "state/routing/utils";
import { TransactionOriginType } from "uniswap/src/features/transactions/types/transactionDetails";
import { computeRealizedPriceImpact } from "utils/prices";
const formatRoutesEventProperties = (routes) => {
  if (!routes) {
    return {};
  }
  const routesEventProperties = {
    routes_percentages: [],
    routes_protocols: []
  };
  routes.forEach((route, index) => {
    routesEventProperties["routes_percentages"].push(formatPercentNumber(route.percent));
    routesEventProperties["routes_protocols"].push(route.protocol);
    routesEventProperties[`route_${index}_input_currency_symbols`] = route.path.map(
      (pathStep) => pathStep[0].symbol ?? ""
    );
    routesEventProperties[`route_${index}_output_currency_symbols`] = route.path.map(
      (pathStep) => pathStep[1].symbol ?? ""
    );
    routesEventProperties[`route_${index}_input_currency_addresses`] = route.path.map(
      (pathStep) => getTokenAddress(pathStep[0])
    );
    routesEventProperties[`route_${index}_output_currency_addresses`] = route.path.map(
      (pathStep) => getTokenAddress(pathStep[1])
    );
    routesEventProperties[`route_${index}_fee_amounts_hundredths_of_bps`] = route.path.map((pathStep) => pathStep[2]);
  });
  return routesEventProperties;
};
export const formatSwapPriceUpdatedEventProperties = (trade, priceUpdate, response) => ({
  chain_id: trade.inputAmount.currency.chainId === trade.outputAmount.currency.chainId ? trade.inputAmount.currency.chainId : void 0,
  response,
  token_in_symbol: trade.inputAmount.currency.symbol,
  token_out_symbol: trade.outputAmount.currency.symbol,
  price_update_basis_points: priceUpdate
});
export const formatSwapButtonClickEventProperties = ({
  trade,
  swapResult,
  allowedSlippage,
  transactionDeadlineSecondsSinceEpoch,
  isAutoSlippage,
  isAutoRouterApi,
  routes,
  fiatValueInput,
  fiatValueOutput
}) => ({
  estimated_network_fee_usd: isClassicTrade(trade) ? trade.gasUseEstimateUSD : void 0,
  transaction_hash: swapResult?.type === TradeFillType.Classic ? swapResult.response.hash : void 0,
  order_hash: isUniswapXTradeType(swapResult?.type) ? swapResult.response.orderHash : void 0,
  transaction_deadline_seconds: getDurationUntilTimestampSeconds(transactionDeadlineSecondsSinceEpoch),
  token_in_address: trade ? getTokenAddress(trade.inputAmount.currency) : void 0,
  token_out_address: trade ? getTokenAddress(trade.outputAmount.currency) : void 0,
  token_in_symbol: trade.inputAmount.currency.symbol,
  token_out_symbol: trade.outputAmount.currency.symbol,
  token_in_amount: trade ? formatToDecimal(trade.inputAmount, trade.inputAmount.currency.decimals) : void 0,
  token_out_amount: trade ? formatToDecimal(trade.outputAmount, trade.outputAmount.currency.decimals) : void 0,
  token_in_amount_usd: fiatValueInput,
  token_out_amount_usd: fiatValueOutput,
  price_impact_basis_points: isClassicTrade(trade) ? formatPercentInBasisPointsNumber(computeRealizedPriceImpact(trade)) : void 0,
  allowed_slippage_basis_points: formatPercentInBasisPointsNumber(allowedSlippage),
  is_auto_router_api: isAutoRouterApi,
  is_auto_slippage: isAutoSlippage,
  transactionOriginType: TransactionOriginType.Internal,
  chain_id: trade.inputAmount.currency.chainId === trade.outputAmount.currency.chainId ? trade.inputAmount.currency.chainId : void 0,
  swap_quote_block_number: isClassicTrade(trade) ? trade.blockNumber : void 0,
  ...formatRoutesEventProperties(routes)
});
