"use strict";
export function hasURL(str) {
  if (!str) {
    return false;
  }
  const pattern = new RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
    // optional port and path
  );
  return pattern.test(str);
}
