"use strict";
import { useMemo } from "react";
import {
  useCollectionQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
export function formatCollectionQueryData(queryCollection, address) {
  const market = queryCollection?.markets?.[0];
  if (!address && !queryCollection?.nftContracts?.[0]?.address) {
    return {};
  }
  const traits = {};
  if (queryCollection?.traits) {
    queryCollection?.traits.forEach((trait) => {
      if (trait.name && trait.stats) {
        traits[trait.name] = trait.stats.map((stats) => {
          return {
            trait_type: stats.name,
            trait_value: stats.value,
            trait_count: stats.assets
          };
        });
      }
    });
  }
  return {
    address: address ?? queryCollection?.nftContracts?.[0]?.address ?? "",
    isVerified: queryCollection?.isVerified,
    name: queryCollection?.name,
    description: queryCollection?.description,
    standard: queryCollection?.nftContracts?.[0]?.standard,
    bannerImageUrl: queryCollection?.bannerImage?.url,
    stats: {
      num_owners: market?.owners,
      floor_price: market?.floorPrice?.value,
      one_day_volume: market?.volume?.value,
      one_day_change: market?.volumePercentChange?.value,
      one_day_floor_change: market?.floorPricePercentChange?.value,
      banner_image_url: queryCollection?.bannerImage?.url,
      total_supply: queryCollection?.numAssets,
      total_listings: market?.listings?.value,
      total_volume: market?.totalVolume?.value
    },
    traits,
    marketplaceCount: market?.marketplaces?.map((market2) => {
      return {
        marketplace: market2.marketplace?.toLowerCase() ?? "",
        count: market2.listings ?? 0,
        floorPrice: market2.floorPrice ?? 0
      };
    }),
    imageUrl: queryCollection?.image?.url ?? "",
    twitterUrl: queryCollection?.twitterName,
    instagram: queryCollection?.instagramName,
    discordUrl: queryCollection?.discordUrl,
    externalUrl: queryCollection?.homepageUrl,
    rarityVerified: false
    // TODO update when backend supports
    // isFoundation: boolean, // TODO ask backend to add
  };
}
export function useCollection(address, skip) {
  const { data: queryData, loading } = useCollectionQuery({
    variables: {
      addresses: address
    },
    skip
  });
  const queryCollection = queryData?.nftCollections?.edges?.[0]?.node;
  return useMemo(() => {
    return {
      data: formatCollectionQueryData(queryCollection, address),
      loading
    };
  }, [address, loading, queryCollection]);
}
