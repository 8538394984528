"use strict";
import Column from "components/Column";
import CurrencyLogo from "components/Logo/CurrencyLogo";
import Row from "components/Row";
import { MouseoverTooltip } from "components/Tooltip";
import { useWindowSize } from "hooks/screenSize";
import styled from "lib/styled-components";
import { BREAKPOINTS } from "theme";
import { ThemedText } from "theme/components";
import { NumberType, useFormatter } from "utils/formatNumbers";
const Label = styled(ThemedText.BodySmall)`
  cursor: ${({ cursor }) => cursor};
  color: ${({ theme }) => theme.neutral2};
  margin-right: 8px;
`;
const ResponsiveHeadline = ({ children, ...textProps }) => {
  const { width } = useWindowSize();
  if (width && width < BREAKPOINTS.xs) {
    return <ThemedText.HeadlineMedium {...textProps}>{children}</ThemedText.HeadlineMedium>;
  }
  return <ThemedText.HeadlineLarge {...textProps}>{children}</ThemedText.HeadlineLarge>;
};
export function SwapModalHeaderAmount({
  tooltipText,
  label,
  amount,
  usdAmount,
  field,
  currency,
  isLoading,
  headerTextProps
}) {
  const { formatNumber, formatReviewSwapCurrencyAmount } = useFormatter();
  return <Row align="center" justify="space-between" gap="md">
    <Column gap="xs">
      {label && <ThemedText.BodySecondary><MouseoverTooltip text={tooltipText} disabled={!tooltipText}><Label cursor={tooltipText ? "help" : void 0}>{label}</Label></MouseoverTooltip></ThemedText.BodySecondary>}
      <Column gap="xs">
        <ResponsiveHeadline
          data-testid={`${field}-amount`}
          color={isLoading ? "neutral2" : void 0}
          {...headerTextProps}
        >
          {formatReviewSwapCurrencyAmount(amount)}
          {" "}
          {currency?.symbol}
        </ResponsiveHeadline>
        <ThemedText.BodySmall color="neutral2">{formatNumber({
          input: usdAmount,
          type: NumberType.FiatTokenQuantity
        })}</ThemedText.BodySmall>
      </Column>
    </Column>
    <CurrencyLogo currency={currency} size={36} />
  </Row>;
}
