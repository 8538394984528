"use strict";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useMemo } from "react";
import { INTERNAL_ROUTER_PREFERENCE_PRICE } from "state/routing/types";
import { currencyAddressForSwapQuote } from "state/routing/utils";
import { ArbitrumXV2OpenOrderProperties, Experiments } from "uniswap/src/features/gating/experiments";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useExperimentGroupName, useExperimentValue, useFeatureFlag } from "uniswap/src/features/gating/hooks";
import { UniverseChainId } from "uniswap/src/types/chains";
var ArbitrumXV2ExperimentGroup = /* @__PURE__ */ ((ArbitrumXV2ExperimentGroup2) => {
  ArbitrumXV2ExperimentGroup2["Test"] = "Test";
  ArbitrumXV2ExperimentGroup2["Control"] = "Control";
  return ArbitrumXV2ExperimentGroup2;
})(ArbitrumXV2ExperimentGroup || {});
export function useRoutingAPIArguments({
  account,
  tokenIn,
  tokenOut,
  amount,
  tradeType,
  routerPreference,
  protocolPreferences
}) {
  const uniswapXForceSyntheticQuotes = useFeatureFlag(FeatureFlags.UniswapXSyntheticQuote);
  const isXv2 = useFeatureFlag(FeatureFlags.UniswapXv2);
  const xv2ArbitrumEnabled = useExperimentGroupName(Experiments.ArbitrumXV2OpenOrders) === "Test" /* Test */;
  const isXv2Arbitrum = tokenIn?.chainId === UniverseChainId.ArbitrumOne && xv2ArbitrumEnabled;
  const priceImprovementBps = useExperimentValue(
    Experiments.ArbitrumXV2OpenOrders,
    ArbitrumXV2OpenOrderProperties.PriceImprovementBps,
    0
  );
  const forceOpenOrders = useExperimentValue(
    Experiments.ArbitrumXV2OpenOrders,
    ArbitrumXV2OpenOrderProperties.ForceOpenOrders,
    false
  );
  const deadlineBufferSecs = useExperimentValue(
    Experiments.ArbitrumXV2OpenOrders,
    ArbitrumXV2OpenOrderProperties.DeadlineBufferSecs,
    30
  );
  const arbitrumXV2SlippageTolerance = useExperimentValue(
    Experiments.ArbitrumXV2OpenOrders,
    ArbitrumXV2OpenOrderProperties.SlippageTolerance,
    "0.5"
  );
  const sendPortionEnabled = routerPreference !== INTERNAL_ROUTER_PREFERENCE_PRICE;
  return useMemo(
    () => !tokenIn || !tokenOut || !amount || tokenIn.equals(tokenOut) || tokenIn.wrapped.equals(tokenOut.wrapped) ? skipToken : {
      account,
      amount: amount.quotient.toString(),
      tokenInAddress: currencyAddressForSwapQuote(tokenIn),
      tokenInChainId: tokenIn.chainId,
      tokenInDecimals: tokenIn.wrapped.decimals,
      tokenInSymbol: tokenIn.wrapped.symbol,
      tokenOutAddress: currencyAddressForSwapQuote(tokenOut),
      tokenOutChainId: tokenOut.wrapped.chainId,
      tokenOutDecimals: tokenOut.wrapped.decimals,
      tokenOutSymbol: tokenOut.wrapped.symbol,
      routerPreference,
      protocolPreferences,
      tradeType,
      needsWrapIfUniswapX: tokenIn.isNative,
      uniswapXForceSyntheticQuotes,
      sendPortionEnabled,
      isXv2,
      isXv2Arbitrum,
      priceImprovementBps,
      forceOpenOrders,
      deadlineBufferSecs,
      arbitrumXV2SlippageTolerance
    },
    [
      tokenIn,
      tokenOut,
      amount,
      account,
      routerPreference,
      protocolPreferences,
      tradeType,
      uniswapXForceSyntheticQuotes,
      sendPortionEnabled,
      isXv2,
      isXv2Arbitrum,
      priceImprovementBps,
      forceOpenOrders,
      deadlineBufferSecs,
      arbitrumXV2SlippageTolerance
    ]
  );
}
