"use strict";
export const hideSmallBalancesAtomName = "hideSmallBalances";
export const hideSpamBalancesAtomName = "hideSpamBalances";
export const migration14 = (state) => {
  if (!state) {
    return;
  }
  const newState = { ...state };
  const atomSmallBalancesValue = localStorage.getItem(hideSmallBalancesAtomName);
  const atomSpamValue = localStorage.getItem(hideSpamBalancesAtomName);
  newState.userSettings = {
    hideSmallBalances: atomSmallBalancesValue !== "false",
    hideSpamTokens: atomSpamValue !== "false"
  };
  localStorage.removeItem(hideSmallBalancesAtomName);
  localStorage.removeItem(hideSpamBalancesAtomName);
  return { ...newState, _persist: { ...state._persist, version: 14 } };
};
