"use strict";
import { CHAIN_IDS_TO_NAMES } from "constants/chains";
function getChainIdFromName(name) {
  const entry = Object.entries(CHAIN_IDS_TO_NAMES).find(([, n]) => n === name);
  const chainId = entry?.[0];
  return chainId ? parseInt(chainId) : void 0;
}
export function getParsedChainId(parsedQs) {
  const chain = parsedQs?.chain;
  if (!chain || typeof chain !== "string") {
    return;
  }
  return getChainIdFromName(chain);
}
