"use strict";
import { BACKEND_SUPPORTED_CHAINS } from "constants/chains";
import { useMemo } from "react";
import {
  useSearchTokensWebQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
function isExploreSupportedToken(token) {
  return token !== void 0 && BACKEND_SUPPORTED_CHAINS.includes(token.chain);
}
export function useSearchTokens(searchQuery = "") {
  const { data, loading, error } = useSearchTokensWebQuery({ variables: { searchQuery }, skip: searchQuery === "" });
  return useMemo(() => {
    const sortedTokens = data?.searchTokens?.filter(isExploreSupportedToken) ?? [];
    return { data: sortedTokens, loading, error };
  }, [data?.searchTokens, loading, error]);
}
