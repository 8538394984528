import * as SentryReact from "@sentry/react";
export function captureException(error, captureContext) {
  SentryReact.captureException(error, captureContext);
}
export function captureMessage(level, context, message, ...extraArgs) {
  SentryReact.captureMessage(message, {
    level,
    tags: { webContext: context },
    ...extraArgs ? { extra: { data: extraArgs } } : {}
  });
}
function addBreadCrumb(breadCrumb) {
  SentryReact.addBreadcrumb(breadCrumb);
}
export const Sentry = {
  captureException,
  captureMessage,
  addBreadCrumb
};
