"use strict";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import ms from "ms";
import {
  QuoteState,
  RouterPreference
} from "state/routing/types";
import { isExactInput, transformQuickRouteToTrade } from "state/routing/utils";
import { logSwapQuoteRequest } from "tracing/swapFlowLoggers";
import { trace } from "tracing/trace";
import { InterfaceEventNameLocal } from "uniswap/src/features/telemetry/constants";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
const UNISWAP_GATEWAY_DNS_URL = process.env.REACT_APP_UNISWAP_GATEWAY_DNS;
if (UNISWAP_GATEWAY_DNS_URL === void 0) {
  throw new Error(`UNISWAP_GATEWAY_DNS_URL must be a defined environment variable`);
}
export const quickRouteApi = createApi({
  reducerPath: "quickRouteApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => ({
    getQuickRoute: build.query({
      queryFn(args, _api, _extraOptions, fetch) {
        return trace({ name: "QuickRoute", op: "quote.quick_route" }, async (trace2) => {
          logSwapQuoteRequest(args.tokenInChainId, RouterPreference.API, true);
          const { tokenInAddress, tokenInChainId, tokenOutAddress, tokenOutChainId, amount, tradeType } = args;
          const type = isExactInput(tradeType) ? "EXACT_IN" : "EXACT_OUT";
          const requestBody = {
            tokenInChainId,
            tokenInAddress,
            tokenOutChainId,
            tokenOutAddress,
            amount,
            tradeType: type
          };
          const response = await fetch({
            method: "GET",
            url: `${UNISWAP_GATEWAY_DNS_URL}/quickroute`,
            params: requestBody
          });
          if (response.error) {
            const errorData = response.error.data;
            if (typeof errorData === "object" && (errorData?.errorCode === "NO_ROUTE" || errorData?.detail === "No quotes available")) {
              sendAnalyticsEvent(InterfaceEventNameLocal.NoQuoteReceivedFromQuickrouteAPI, {
                requestBody,
                response
              });
              return {
                data: { state: QuoteState.NOT_FOUND, latencyMs: trace2.now() }
              };
            } else {
              trace2.setError(response.error);
              return { error: response.error };
            }
          }
          const quickRouteResponse = response.data;
          const previewTrade = transformQuickRouteToTrade(args, quickRouteResponse);
          return {
            data: {
              state: QuoteState.SUCCESS,
              trade: previewTrade,
              latencyMs: trace2.now()
            }
          };
        });
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0
      }
    })
  })
});
export const { useGetQuickRouteQuery } = quickRouteApi;
export const useGetQuickRouteQueryState = quickRouteApi.endpoints.getQuickRoute.useQueryState;
