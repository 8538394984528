"use strict";
import Column from "components/Column";
import RouterLabel from "components/RouterLabel";
import RoutingDiagram from "components/RoutingDiagram/RoutingDiagram";
import { RowBetween } from "components/Row";
import { UniswapXDescription } from "components/swap/GasBreakdownTooltip";
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from "constants/chains";
import { isClassicTrade } from "state/routing/utils";
import { Separator, ThemedText } from "theme/components";
import { Trans } from "uniswap/src/i18n";
import { NumberType, useFormatter } from "utils/formatNumbers";
import getRoutingDiagramEntries from "utils/getRoutingDiagramEntries";
function useGasPrice({ gasUseEstimateUSD, inputAmount }) {
  const { formatNumber } = useFormatter();
  if (!gasUseEstimateUSD || !SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(inputAmount.currency.chainId)) {
    return void 0;
  }
  return gasUseEstimateUSD === 0 ? "<$0.01" : formatNumber({ input: gasUseEstimateUSD, type: NumberType.FiatGasPrice });
}
function RouteLabel({ trade }) {
  return <RowBetween>
    <ThemedText.BodySmall color="neutral2"><Trans i18nKey="swap.orderRouting" /></ThemedText.BodySmall>
    <RouterLabel trade={trade} color="neutral1" />
  </RowBetween>;
}
function PriceImpactRow({ trade }) {
  const { formatPercent } = useFormatter();
  return <ThemedText.BodySmall color="neutral2"><RowBetween>
    <Trans i18nKey="swap.priceImpact" />
    <div>{formatPercent(trade.priceImpact)}</div>
  </RowBetween></ThemedText.BodySmall>;
}
export function RoutingTooltip({ trade }) {
  return isClassicTrade(trade) ? <Column gap="md">
    <PriceImpactRow trade={trade} />
    <Separator />
    <RouteLabel trade={trade} />
    <SwapRoute trade={trade} />
  </Column> : <Column gap="md">
    <RouteLabel trade={trade} />
    <Separator />
    <UniswapXDescription />
  </Column>;
}
export function SwapRoute({ trade }) {
  const { inputAmount, outputAmount } = trade;
  const routes = getRoutingDiagramEntries(trade);
  const gasPrice = useGasPrice(trade);
  return <Column gap="md">
    <RoutingDiagram routes={routes} currencyIn={inputAmount.currency} currencyOut={outputAmount.currency} />
    <ThemedText.Caption color="neutral2">
      {Boolean(gasPrice) && <Trans i18nKey="swap.bestRoute.cost" values={{ gasPrice }} />}
      {Boolean(gasPrice) && " "}
      <Trans i18nKey="swap.route.optimizedGasCost" />
    </ThemedText.Caption>
  </Column>;
}
