"use strict";
import { GetHelpHeader } from "components/Modal/GetHelpHeader";
import { useAccount } from "hooks/useAccount";
import ms from "ms";
import { useBuyFormContext } from "pages/Swap/Buy/BuyFormContext";
import { ProviderConnectedView } from "pages/Swap/Buy/ProviderConnectedView";
import { ProviderConnectionError } from "pages/Swap/Buy/ProviderConnectionError";
import { ProviderOption } from "pages/Swap/Buy/ProviderOption";
import { ContentWrapper } from "pages/Swap/Buy/shared";
import { useMemo, useState } from "react";
import { AdaptiveWebModal, Flex, Separator, Text } from "ui/src";
import { TimePast } from "ui/src/components/icons/TimePast";
import { uniswapUrls } from "uniswap/src/constants/urls";
import { Trans } from "uniswap/src/i18n";
import { logger } from "utilities/src/logger/logger";
import { useInterval } from "utilities/src/time/timing";
function ChooseProviderModalContent({ closeModal }) {
  const { derivedBuyFormInfo, buyFormState } = useBuyFormContext();
  const { quoteCurrency, selectedCountry, inputAmount } = buyFormState;
  const { quotes, meldSupportedFiatCurrency } = derivedBuyFormInfo;
  const [errorProvider, setErrorProvider] = useState();
  const [connectedProvider, setConnectedProvider] = useState();
  const account = useAccount();
  const [mostRecentlyUsedProvider, otherProviders] = useMemo(() => {
    if (!quotes || !quotes.quotes) {
      return [void 0, []];
    }
    const mostRecent = quotes.quotes.find((q) => q.isMostRecentlyUsedProvider);
    if (mostRecent) {
      return [mostRecent, quotes.quotes.filter((q) => !q.isMostRecentlyUsedProvider)];
    }
    return [void 0, quotes.quotes];
  }, [quotes]);
  const onClose = () => {
    closeModal();
    setTimeout(() => {
      setErrorProvider(void 0);
      setConnectedProvider(void 0);
    }, ms("500ms"));
  };
  useInterval(() => {
    if (!errorProvider && !connectedProvider) {
      onClose();
    }
  }, ms("5m"));
  const quoteCurrencyCode = quoteCurrency.meldCurrencyCode;
  const recipientAddress = account.address;
  if (!selectedCountry || !quoteCurrencyCode || !meldSupportedFiatCurrency || !recipientAddress) {
    logger.debug("ChooseProviderModal", "ChooseProviderModalContent", "Modal opened with invalid state. Closing modal.");
    onClose();
    return null;
  }
  if (errorProvider) {
    return <ProviderConnectionError
      onBack={() => setErrorProvider(void 0)}
      closeModal={onClose}
      selectedServiceProvider={errorProvider}
    />;
  }
  if (connectedProvider) {
    return <ProviderConnectedView closeModal={onClose} selectedServiceProvider={connectedProvider} />;
  }
  return <Flex gap="$spacing24" pb="$spacing8" $sm={{ px: "$spacing8", pb: "$spacing16" }} id="ChooseProviderModal">
    <GetHelpHeader
      title={<Trans i18nKey="fiatOnRamp.checkoutWith" />}
      link={uniswapUrls.helpArticleUrls.fiatOnRampHelp}
      closeModal={closeModal}
      closeDataTestId="ChooseProviderModal-close"
    />
    <Flex gap="16px">
      {mostRecentlyUsedProvider && <Flex gap="$spacing12">
        <Flex row alignItems="center" pb="$spacing12" pl="$spacing8">
          <TimePast color="$neutral3" size="$icon.16" />
          <Text color="$neutral2" pl="$spacing4" variant="body3"><Trans i18nKey="fiatOnRamp.quote.type.recent" /></Text>
        </Flex>
        <ProviderOption
          key={mostRecentlyUsedProvider.serviceProviderDetails.serviceProvider}
          quote={mostRecentlyUsedProvider}
          selectedCountry={selectedCountry}
          quoteCurrencyCode={quoteCurrencyCode}
          inputAmount={inputAmount}
          meldSupportedFiatCurrency={meldSupportedFiatCurrency}
          walletAddress={recipientAddress}
          setConnectedProvider={setConnectedProvider}
          setErrorProvider={setErrorProvider}
        />
        {otherProviders && otherProviders.length > 0 && <Flex centered row gap="$spacing12" mt="$spacing12">
          <Separator />
          <Text color="$neutral3" variant="body3"><Trans i18nKey="fiatOnRamp.quote.type.other" /></Text>
          <Separator />
        </Flex>}
      </Flex>}
      {otherProviders?.map((q) => {
        return <ProviderOption
          key={q.serviceProviderDetails.serviceProvider}
          quote={q}
          selectedCountry={selectedCountry}
          quoteCurrencyCode={quoteCurrencyCode}
          inputAmount={inputAmount}
          meldSupportedFiatCurrency={meldSupportedFiatCurrency}
          walletAddress={recipientAddress}
          setConnectedProvider={setConnectedProvider}
          setErrorProvider={setErrorProvider}
        />;
      })}
      <Text variant="body3" textAlign="center" color="$neutral2"><Trans i18nKey="fiatOnRamp.chooseProvider.description" /></Text>
    </Flex>
  </Flex>;
}
export function ChooseProviderModal(props) {
  return <AdaptiveWebModal isOpen={props.isOpen} onClose={props.closeModal} width={420}><ContentWrapper><ChooseProviderModalContent {...props} /></ContentWrapper></AdaptiveWebModal>;
}
