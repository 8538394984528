"use strict";
import { BigNumber } from "@ethersproject/bignumber";
import { CustomUserProperties, SwapEventName } from "@uniswap/analytics-events";
import { DutchOrderBuilder, V2DutchOrderBuilder } from "@uniswap/uniswapx-sdk";
import { useTotalBalancesUsdForAnalytics } from "graphql/data/apollo/TokenBalancesProvider";
import { useAccount } from "hooks/useAccount";
import { useEthersWeb3Provider } from "hooks/useEthersProvider";
import { formatSwapSignedAnalyticsEventProperties } from "lib/utils/analytics";
import { useCallback, useRef } from "react";
import {
  OffchainOrderType,
  TradeFillType,
  V2DutchOrderTrade
} from "state/routing/types";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { trace } from "tracing/trace";
import { InterfaceEventNameLocal } from "uniswap/src/features/telemetry/constants";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { logger } from "utilities/src/logger/logger";
import { useTrace } from "utilities/src/telemetry/trace/TraceContext";
import {
  SignatureExpiredError,
  UniswapXv2HardQuoteError,
  UserRejectedRequestError,
  WrongChainError
} from "utils/errors";
import { signTypedData } from "utils/signing";
import { didUserReject, swapErrorToUserReadableMessage } from "utils/swapErrorToUserReadableMessage";
import { getWalletMeta } from "utils/walletMeta";
function isV2DutchAuctionOrderSuccess(response) {
  return response.orderHash !== void 0;
}
const isErrorResponse = (res, order) => res.status < 200 || res.status > 202;
const UNISWAP_GATEWAY_DNS_URL = process.env.REACT_APP_UNISWAP_GATEWAY_DNS;
if (UNISWAP_GATEWAY_DNS_URL === void 0) {
  throw new Error(`UNISWAP_GATEWAY_DNS_URL must be defined environment variables`);
}
async function getUpdatedNonce(swapper, chainId) {
  try {
    const res = await fetch(`${UNISWAP_GATEWAY_DNS_URL}/nonce?address=${swapper.toLowerCase()}&chainId=${chainId}`);
    const { nonce } = await res.json();
    return BigNumber.from(nonce).add(1);
  } catch (e) {
    logger.error(e, {
      tags: {
        file: "useUniswapXSwapCallback",
        function: "getUpdatedNonce"
      }
    });
    return null;
  }
}
export function useUniswapXSwapCallback({
  trade,
  allowedSlippage,
  fiatValues
}) {
  const account = useAccount();
  const accountRef = useRef(account);
  accountRef.current = account;
  const { chainId } = useSwapAndLimitContext();
  const provider = useEthersWeb3Provider({ chainId });
  const providerRef = useRef(provider);
  providerRef.current = provider;
  const analyticsContext = useTrace();
  const portfolioBalanceUsd = useTotalBalancesUsdForAnalytics();
  return useCallback(
    () => trace({ name: "Swap (Dutch)", op: "swap.x.dutch" }, async (trace2) => {
      const account2 = accountRef.current;
      const provider2 = providerRef.current;
      if (account2.status !== "connected") {
        throw new Error("wallet not connected");
      }
      if (!provider2) {
        throw new Error("missing provider");
      }
      if (!trade) {
        throw new Error("missing trade");
      }
      const connectedChainId = await provider2.getSigner().getChainId();
      if (account2.chainId !== connectedChainId || account2.chainId !== chainId) {
        throw new WrongChainError();
      }
      sendAnalyticsEvent(InterfaceEventNameLocal.UniswapXSignatureRequested, {
        ...formatSwapSignedAnalyticsEventProperties({
          trade,
          allowedSlippage,
          fiatValues,
          portfolioBalanceUsd
        }),
        ...analyticsContext
      });
      try {
        const updatedNonce = await getUpdatedNonce(account2.address, trade.inputAmount.currency.chainId);
        const now = Math.floor(Date.now() / 1e3);
        let deadline;
        let domain;
        let types;
        let values;
        let updatedOrder;
        if (trade instanceof V2DutchOrderTrade) {
          deadline = now + trade.deadlineBufferSecs;
          const order = trade.order;
          updatedOrder = V2DutchOrderBuilder.fromOrder(order).deadline(deadline).nonFeeRecipient(account2.address, trade.swapFee?.recipient).nonce(updatedNonce ?? order.info.nonce).buildPartial();
          ({ domain, types, values } = updatedOrder.permitData());
        } else {
          const startTime = now + trade.startTimeBufferSecs;
          const endTime = startTime + trade.auctionPeriodSecs;
          deadline = endTime + trade.deadlineBufferSecs;
          const order = trade.asDutchOrderTrade({ nonce: updatedNonce, swapper: account2.address }).order;
          updatedOrder = DutchOrderBuilder.fromOrder(order).decayStartTime(startTime).decayEndTime(endTime).deadline(deadline).nonFeeRecipient(account2.address, trade.swapFee?.recipient).nonce(updatedNonce ?? order.info.nonce).build();
          ({ domain, types, values } = updatedOrder.permitData());
          trace2.setData("startTime", startTime);
          trace2.setData("endTime", endTime);
        }
        trace2.setData("deadline", deadline);
        const signature = await trace2.child({ name: "Sign", op: "wallet.sign" }, async (walletTrace) => {
          try {
            const provider3 = providerRef.current;
            if (!provider3) {
              throw new Error("missing provider");
            }
            const account3 = accountRef.current;
            return await signTypedData(provider3.getSigner(account3.address), domain, types, values);
          } catch (error) {
            if (didUserReject(error)) {
              walletTrace.setStatus("cancelled");
              throw new UserRejectedRequestError(swapErrorToUserReadableMessage(error));
            } else {
              throw error;
            }
          }
        });
        const resultTime = Math.floor(Date.now() / 1e3);
        if (deadline < resultTime) {
          sendAnalyticsEvent(InterfaceEventNameLocal.UniswapXSignatureDeadlineExpired, {
            ...formatSwapSignedAnalyticsEventProperties({
              trade,
              allowedSlippage,
              fiatValues,
              portfolioBalanceUsd
            }),
            ...analyticsContext,
            deadline,
            resultTime
          });
          throw new SignatureExpiredError();
        }
        sendAnalyticsEvent(SwapEventName.SWAP_SIGNED, {
          ...formatSwapSignedAnalyticsEventProperties({
            trade,
            allowedSlippage,
            fiatValues,
            timeToSignSinceRequestMs: trace2.now(),
            portfolioBalanceUsd
          }),
          ...analyticsContext,
          // TODO (WEB-2993): remove these after debugging missing user properties.
          [CustomUserProperties.WALLET_ADDRESS]: account2.address,
          [CustomUserProperties.WALLET_TYPE]: account2.connector.name,
          [CustomUserProperties.PEER_WALLET_AGENT]: provider2 ? getWalletMeta(provider2)?.agent : void 0
        });
        const encodedOrder = updatedOrder.serialize();
        let endpoint;
        let body;
        if (trade.offchainOrderType === OffchainOrderType.DUTCH_V2_AUCTION) {
          endpoint = "rfq";
          body = {
            encodedInnerOrder: encodedOrder,
            innerSig: signature,
            tokenInChainId: updatedOrder.chainId,
            tokenOutChainId: updatedOrder.chainId,
            requestId: trade.requestId,
            quoteId: trade.quoteId,
            forceOpenOrder: trade.forceOpenOrder
          };
        } else {
          endpoint = trade.offchainOrderType === OffchainOrderType.LIMIT_ORDER ? "limit-order" : "order";
          body = {
            encodedOrder,
            orderType: trade.offchainOrderType,
            signature,
            chainId: updatedOrder.chainId,
            quoteId: trade.quoteId
          };
        }
        const res = await fetch(`${UNISWAP_GATEWAY_DNS_URL}/${endpoint}`, {
          method: "POST",
          body: JSON.stringify(body)
        });
        const responseBody = await res.json();
        if (isErrorResponse(res, responseBody)) {
          sendAnalyticsEvent(InterfaceEventNameLocal.UniswapXOrderPostError, {
            ...formatSwapSignedAnalyticsEventProperties({
              trade,
              allowedSlippage,
              fiatValues,
              portfolioBalanceUsd
            }),
            ...analyticsContext,
            errorCode: responseBody.errorCode,
            detail: responseBody.detail
          });
          if (trade?.fillType === TradeFillType.UniswapXv2) {
            throw new UniswapXv2HardQuoteError();
          }
          throw new Error(`${responseBody.errorCode ?? responseBody.detail ?? "Unknown error"}`);
        }
        sendAnalyticsEvent(InterfaceEventNameLocal.UniswapXOrderSubmitted, {
          ...formatSwapSignedAnalyticsEventProperties({
            trade,
            allowedSlippage,
            fiatValues,
            portfolioBalanceUsd
          })
        });
        return {
          type: trade.offchainOrderType === OffchainOrderType.DUTCH_V2_AUCTION ? TradeFillType.UniswapXv2 : TradeFillType.UniswapX,
          response: {
            orderHash: isV2DutchAuctionOrderSuccess(responseBody) ? responseBody.orderHash : responseBody.hash,
            deadline: updatedOrder.info.deadline,
            encodedOrder
          }
        };
      } catch (error) {
        if (error instanceof UserRejectedRequestError) {
          trace2.setStatus("cancelled");
          throw error;
        } else if (error instanceof SignatureExpiredError || error instanceof UniswapXv2HardQuoteError) {
          trace2.setStatus("unknown_error");
          throw error;
        } else {
          trace2.setError(error);
          throw new Error(swapErrorToUserReadableMessage(error));
        }
      }
    }),
    [chainId, trade, allowedSlippage, fiatValues, portfolioBalanceUsd, analyticsContext]
  );
}
