"use strict";
import {
  CustomHistogramSeriesRenderer
} from "components/Charts/VolumeChart/renderer";
import { getCumulativeSum } from "components/Charts/VolumeChart/utils";
import {
  customSeriesDefaultOptions
} from "lightweight-charts";
export class CustomHistogramSeries {
  _renderer;
  _colors;
  _isMultichainExploreEnabled;
  _background;
  constructor(props) {
    this._renderer = new CustomHistogramSeriesRenderer(props);
    this._colors = props.colors;
    this._isMultichainExploreEnabled = props.isMultichainExploreEnabled;
    this._background = props.background;
  }
  priceValueBuilder(plotRow) {
    return [0, getCumulativeSum(plotRow)];
  }
  isWhitespace(data) {
    return !data.time || !getCumulativeSum(data);
  }
  renderer() {
    return this._renderer;
  }
  update(data, options) {
    this._renderer.update(data, options);
  }
  defaultOptions() {
    return {
      ...customSeriesDefaultOptions,
      colors: this._colors
    };
  }
}
