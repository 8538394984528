"use strict";
import { SOCKS_CONTROLLER_ADDRESSES, Token } from "@uniswap/sdk-core";
import { useAccount } from "hooks/useAccount";
import { useTokenBalance } from "lib/hooks/useCurrencyBalance";
import { useMemo } from "react";
import { UniverseChainId } from "uniswap/src/types/chains";
const SOCKS = new Token(UniverseChainId.Mainnet, SOCKS_CONTROLLER_ADDRESSES[UniverseChainId.Mainnet], 0);
export function useHasSocks() {
  const account = useAccount();
  const balance = useTokenBalance(account.address, account.chainId === UniverseChainId.Mainnet ? SOCKS : void 0);
  return useMemo(() => Boolean(balance?.greaterThan(0)), [balance]);
}
