"use strict";
import { NftImage, NftPlayableMedia } from "nft/components/card/media";
import {
  LarvaLabsMarketplaceIcon,
  LooksRareIcon,
  Nft20Icon,
  NftXIcon,
  OpenSeaMarketplaceIcon,
  SudoSwapIcon,
  X2y2Icon
} from "nft/components/icons";
import { Markets, UniformAspectRatios } from "nft/types";
import { isAudio, isVideo } from "nft/utils";
import { useCallback } from "react";
var AssetMediaType = /* @__PURE__ */ ((AssetMediaType2) => {
  AssetMediaType2[AssetMediaType2["Image"] = 0] = "Image";
  AssetMediaType2[AssetMediaType2["Video"] = 1] = "Video";
  AssetMediaType2[AssetMediaType2["Audio"] = 2] = "Audio";
  return AssetMediaType2;
})(AssetMediaType || {});
function getAssetImageUrl(asset) {
  return asset.imageUrl || asset.smallImageUrl;
}
function getAssetMediaUrl(asset) {
  return asset.animationUrl;
}
export function detailsHref(asset) {
  if ("address" in asset) {
    return `/nfts/asset/${asset.address}/${asset.tokenId}?origin=collection`;
  }
  if ("asset_contract" in asset) {
    return `/nfts/asset/${asset.asset_contract.address}/${asset.tokenId}?origin=profile`;
  }
  return "/nfts/profile";
}
function getAssetMediaType(asset) {
  let assetMediaType = 0 /* Image */;
  if (asset.animationUrl) {
    if (isAudio(asset.animationUrl)) {
      assetMediaType = 2 /* Audio */;
    } else if (isVideo(asset.animationUrl)) {
      assetMediaType = 1 /* Video */;
    }
  }
  return assetMediaType;
}
export function getNftDisplayComponent(asset, mediaShouldBePlaying, setCurrentTokenPlayingMedia, uniformAspectRatio, setUniformAspectRatio, renderedHeight, setRenderedHeight) {
  switch (getAssetMediaType(asset)) {
    case 0 /* Image */:
      return <NftImage
        src={getAssetImageUrl(asset)}
        uniformAspectRatio={uniformAspectRatio}
        setUniformAspectRatio={setUniformAspectRatio}
        renderedHeight={renderedHeight}
        setRenderedHeight={setRenderedHeight}
      />;
    case 1 /* Video */:
      return <NftPlayableMedia
        src={getAssetImageUrl(asset)}
        mediaSrc={getAssetMediaUrl(asset)}
        tokenId={asset.tokenId}
        shouldPlay={mediaShouldBePlaying}
        setCurrentTokenPlayingMedia={setCurrentTokenPlayingMedia}
        uniformAspectRatio={uniformAspectRatio}
        setUniformAspectRatio={setUniformAspectRatio}
        renderedHeight={renderedHeight}
        setRenderedHeight={setRenderedHeight}
      />;
    case 2 /* Audio */:
      return <NftPlayableMedia
        isAudio={true}
        src={getAssetImageUrl(asset)}
        mediaSrc={getAssetMediaUrl(asset)}
        tokenId={asset.tokenId}
        shouldPlay={mediaShouldBePlaying}
        setCurrentTokenPlayingMedia={setCurrentTokenPlayingMedia}
        uniformAspectRatio={uniformAspectRatio}
        setUniformAspectRatio={setUniformAspectRatio}
        renderedHeight={renderedHeight}
        setRenderedHeight={setRenderedHeight}
      />;
  }
}
export function useSelectAsset({
  selectAsset,
  unselectAsset,
  isSelected,
  isDisabled,
  onClick
}) {
  return useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (isDisabled) {
        return;
      }
      if (onClick) {
        onClick();
        return;
      }
      return isSelected ? unselectAsset?.() : selectAsset?.();
    },
    [selectAsset, isDisabled, onClick, unselectAsset, isSelected]
  );
}
export function getMarketplaceIcon(market) {
  switch (market) {
    case Markets.Opensea:
      return <OpenSeaMarketplaceIcon />;
    case Markets.LooksRare:
      return <LooksRareIcon />;
    case Markets.X2Y2:
      return <X2y2Icon />;
    case Markets.Sudoswap:
      return <SudoSwapIcon />;
    case Markets.NFT20:
      return <Nft20Icon />;
    case Markets.NFTX:
      return <NftXIcon />;
    case Markets.Cryptopunks:
      return <LarvaLabsMarketplaceIcon />;
    default:
      return null;
  }
}
export const handleUniformAspectRatio = (uniformAspectRatio, e, setUniformAspectRatio, renderedHeight, setRenderedHeight) => {
  if (uniformAspectRatio !== UniformAspectRatios.square && setUniformAspectRatio) {
    const height = e.currentTarget.clientHeight;
    const width = e.currentTarget.clientWidth;
    const aspectRatio = width / height;
    if ((!renderedHeight || renderedHeight !== height) && aspectRatio < 1 && uniformAspectRatio !== UniformAspectRatios.square && setRenderedHeight) {
      setRenderedHeight(height);
    }
    const variance = 0.05;
    if (uniformAspectRatio === UniformAspectRatios.unset) {
      setUniformAspectRatio(aspectRatio >= 1 ? UniformAspectRatios.square : aspectRatio);
    } else if (aspectRatio > uniformAspectRatio + variance || aspectRatio < uniformAspectRatio - variance) {
      setUniformAspectRatio(UniformAspectRatios.square);
      setRenderedHeight && setRenderedHeight(void 0);
    }
  }
};
export function getHeightFromAspectRatio(uniformAspectRatio, renderedHeight) {
  return uniformAspectRatio === UniformAspectRatios.square || uniformAspectRatio === UniformAspectRatios.unset ? void 0 : renderedHeight;
}
export function getMediaAspectRatio(uniformAspectRatio, setUniformAspectRatio) {
  return uniformAspectRatio === UniformAspectRatios.square || !setUniformAspectRatio ? "1" : "auto";
}
