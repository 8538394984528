"use strict";
import { DEFAULT_INACTIVE_LIST_URLS } from "constants/lists";
import { tokensToChainTokenMap } from "lib/hooks/useTokenList/utils";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { logger } from "utilities/src/logger/logger";
export function useAllLists() {
  return useAppSelector((state) => state.lists.byUrl);
}
function combineMaps(map1, map2) {
  const chainIds = Object.keys(
    Object.keys(map1).concat(Object.keys(map2)).reduce((memo, value) => {
      memo[value] = true;
      return memo;
    }, {})
  ).map((id) => parseInt(id));
  return chainIds.reduce((memo, chainId) => {
    memo[chainId] = {
      ...map2[chainId],
      // map1 takes precedence
      ...map1[chainId]
    };
    return memo;
  }, {});
}
function useCombinedTokenMapFromUrls(urls) {
  const lists = useAllLists();
  return useMemo(() => {
    if (!urls) {
      return {};
    }
    return urls.slice().reduce((allTokens, currentUrl) => {
      const current = lists?.[currentUrl]?.current;
      if (!current) {
        return allTokens;
      }
      try {
        return combineMaps(allTokens, tokensToChainTokenMap(current));
      } catch (error) {
        logger.warn("lists/hooks", "useCombinedTokenMapFromUrls", "Failed to combine tokens", error);
        return allTokens;
      }
    }, {});
  }, [lists, urls]);
}
export function useCombinedInactiveLists() {
  const inactiveTokens = useCombinedTokenMapFromUrls(DEFAULT_INACTIVE_LIST_URLS);
  return inactiveTokens;
}
