"use strict";
import { Price, TradeType } from "@uniswap/sdk-core";
import { getChain, useSupportedChainId } from "constants/chains";
import { useAccount } from "hooks/useAccount";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useMemo, useRef } from "react";
import { ClassicTrade, INTERNAL_ROUTER_PREFERENCE_PRICE } from "state/routing/types";
import { useRoutingAPITrade } from "state/routing/useRoutingAPITrade";
export default function useStablecoinPrice(currency) {
  const chainId = useSupportedChainId(currency?.chainId);
  const amountOut = chainId ? getChain({ chainId }).spotPriceStablecoinAmount : void 0;
  const stablecoin = amountOut?.currency;
  const { trade, state } = useRoutingAPITrade(
    false,
    TradeType.EXACT_OUTPUT,
    amountOut,
    currency,
    INTERNAL_ROUTER_PREFERENCE_PRICE
  );
  const price = useMemo(() => {
    if (!currency || !stablecoin) {
      return void 0;
    }
    if (currency?.wrapped.equals(stablecoin)) {
      return new Price(stablecoin, stablecoin, "1", "1");
    }
    if (trade && trade instanceof ClassicTrade) {
      const { numerator, denominator } = trade.routes[0].midPrice;
      return new Price(currency, stablecoin, denominator, numerator);
    }
    return void 0;
  }, [currency, stablecoin, trade]);
  const lastPrice = useRef(price);
  if (!price || !lastPrice.current || !price.equalTo(lastPrice.current) || !price.baseCurrency.equals(lastPrice.current.baseCurrency)) {
    lastPrice.current = price;
  }
  return { price: lastPrice.current, state };
}
export function useStablecoinValue(currencyAmount) {
  const { price } = useStablecoinPrice(currencyAmount?.currency);
  return useMemo(() => {
    if (!price || !currencyAmount) {
      return null;
    }
    try {
      return price.quote(currencyAmount);
    } catch (error) {
      return null;
    }
  }, [currencyAmount, price]);
}
export function useStablecoinAmountFromFiatValue(fiatValue) {
  const { chainId } = useAccount();
  const supportedChainId = useSupportedChainId(chainId);
  const stablecoin = supportedChainId ? getChain({ chainId: supportedChainId }).spotPriceStablecoinAmount.currency : void 0;
  return useMemo(() => {
    if (fiatValue === null || fiatValue === void 0 || !chainId || !stablecoin) {
      return void 0;
    }
    const parsedForDecimals = fiatValue.toFixed(stablecoin.decimals).toString();
    try {
      return tryParseCurrencyAmount(parsedForDecimals, stablecoin);
    } catch (error) {
      return void 0;
    }
  }, [chainId, fiatValue, stablecoin]);
}
