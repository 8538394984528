"use strict";
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "constants/locales";
import useParsedQueryString from "hooks/useParsedQueryString";
import { useMemo } from "react";
import store from "state";
import { useUserLocale } from "state/user/hooks";
export function parseLocale(maybeSupportedLocale) {
  if (typeof maybeSupportedLocale !== "string") {
    return void 0;
  }
  const lowerMaybeSupportedLocale = maybeSupportedLocale.toLowerCase();
  return SUPPORTED_LOCALES.find(
    (locale) => locale.toLowerCase() === lowerMaybeSupportedLocale || locale.split("-")[0] === lowerMaybeSupportedLocale
  );
}
export function navigatorLocale() {
  if (!navigator.language) {
    return void 0;
  }
  const [language, region] = navigator.language.split("-");
  if (region) {
    return parseLocale(`${language}-${region.toUpperCase()}`) ?? parseLocale(language);
  }
  return parseLocale(language);
}
export function storeLocale() {
  return store.getState().user.userLocale ?? void 0;
}
function useUrlLocale() {
  const parsed = useParsedQueryString();
  return parseLocale(parsed.lng);
}
export function useActiveLocale() {
  const urlLocale = useUrlLocale();
  const userLocale = useUserLocale();
  return useMemo(() => urlLocale ?? userLocale ?? navigatorLocale() ?? DEFAULT_LOCALE, [urlLocale, userLocale]);
}
