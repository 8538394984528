"use strict";
import { calculateElapsedTimeWithPerformanceMarkMs } from "tracing/utils";
export var SwapEventType = /* @__PURE__ */ ((SwapEventType2) => {
  SwapEventType2["FIRST_SWAP_ACTION"] = "FIRST_SWAP_ACTION";
  SwapEventType2["FIRST_QUOTE_FETCH_STARTED"] = "FIRST_QUOTE_FETCH_STARTED";
  SwapEventType2["FIRST_SWAP_SIGNATURE_REQUESTED"] = "FIRST_SWAP_SIGNATURE_REQUESTED";
  SwapEventType2["FIRST_SWAP_SIGNATURE_COMPLETED"] = "FIRST_SWAP_SIGNATURE_COMPLETED";
  SwapEventType2["FIRST_SWAP_SUCCESS"] = "FIRST_SWAP_SUCCESS";
  return SwapEventType2;
})(SwapEventType || {});
export class SwapEventTimestampTracker {
  static _instance;
  createdAt = Date.now();
  constructor() {
  }
  static getInstance() {
    if (!this._instance) {
      this._instance = new SwapEventTimestampTracker();
    }
    return this._instance;
  }
  timestamps = /* @__PURE__ */ new Map();
  hasTimestamp(eventType) {
    return this.timestamps.has(eventType);
  }
  setElapsedTime(eventType) {
    if (this.timestamps.has(eventType)) {
      return void 0;
    }
    const elapsedTime = calculateElapsedTimeWithPerformanceMarkMs(eventType, this.createdAt);
    if (elapsedTime) {
      this.timestamps.set(eventType, elapsedTime);
    }
    return this.timestamps.get(eventType);
  }
  /**
   * Returns the time elapsed between the given event and the start event,
   * or page load if the start event is not provided.
   */
  getElapsedTime(eventType, startEventType) {
    const endTime = this.timestamps.get(eventType);
    if (!endTime) {
      return void 0;
    }
    let startTime = 0;
    if (startEventType) {
      startTime = this.timestamps.get(startEventType) ?? 0;
    }
    return endTime - startTime;
  }
}
export const timestampTracker = SwapEventTimestampTracker.getInstance();
