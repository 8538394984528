"use strict";
import { Box } from "nft/components/Box";
import { forwardRef } from "react";
export const Row = forwardRef((props, ref) => {
  return <Box ref={ref} display="flex" flexDirection="row" alignItems="center" {...props} />;
});
Row.displayName = "Row";
export const Column = forwardRef((props, ref) => {
  return <Box ref={ref} display="flex" flexDirection="column" {...props} />;
});
Column.displayName = "Column";
export const Center = forwardRef((props, ref) => {
  return <Box ref={ref} display="flex" justifyContent="center" alignItems="center" {...props} />;
});
Center.displayName = "Center";
