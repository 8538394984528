"use strict";
import { DEFAULT_LOCALE } from "constants/locales";
export const migration4 = (state) => {
  if (state?.user) {
    if (state.user.userLocale === "de-DE") {
      state.user.userLocale = DEFAULT_LOCALE;
    }
    return {
      ...state,
      _persist: {
        ...state._persist,
        version: 4
      }
    };
  }
  return state;
};
