"use strict";
import { Percent, Token, V2_FACTORY_ADDRESSES } from "@uniswap/sdk-core";
import { computePairAddress } from "@uniswap/v2-sdk";
import { chainIdToBackendChain, useSupportedChainId } from "constants/chains";
import { L2_DEADLINE_FROM_NOW } from "constants/misc";
import { BASES_TO_TRACK_LIQUIDITY_FOR, PINNED_PAIRS } from "constants/routing";
import { gqlToCurrency } from "graphql/data/util";
import { useAccount } from "hooks/useAccount";
import JSBI from "jsbi";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import {
  addSerializedPair,
  updateHideClosedPositions,
  updateUserDeadline,
  updateUserLocale,
  updateUserRouterPreference,
  updateUserSlippageTolerance
} from "state/user/reducer";
import { SlippageTolerance } from "state/user/types";
import {
  Chain,
  TokenSortableField,
  useTopTokensQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { isL2ChainId } from "uniswap/src/features/chains/utils";
import { deserializeToken, serializeToken } from "uniswap/src/utils/currency";
export function useUserLocale() {
  return useAppSelector((state) => state.user.userLocale);
}
export function useUserLocaleManager() {
  const dispatch = useAppDispatch();
  const locale = useUserLocale();
  const setLocale = useCallback(
    (newLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }));
    },
    [dispatch]
  );
  return [locale, setLocale];
}
export function useRouterPreference() {
  const dispatch = useAppDispatch();
  const routerPreference = useAppSelector((state) => state.user.userRouterPreference);
  const setRouterPreference = useCallback(
    (newRouterPreference) => {
      dispatch(updateUserRouterPreference({ userRouterPreference: newRouterPreference }));
    },
    [dispatch]
  );
  return [routerPreference, setRouterPreference];
}
export function useUserSlippageTolerance() {
  const userSlippageToleranceRaw = useAppSelector((state) => {
    return state.user.userSlippageTolerance;
  });
  const userSlippageTolerance = useMemo(
    () => userSlippageToleranceRaw === SlippageTolerance.Auto ? SlippageTolerance.Auto : new Percent(userSlippageToleranceRaw, 1e4),
    [userSlippageToleranceRaw]
  );
  const dispatch = useAppDispatch();
  const setUserSlippageTolerance = useCallback(
    (userSlippageTolerance2) => {
      let value;
      try {
        value = userSlippageTolerance2 === SlippageTolerance.Auto ? SlippageTolerance.Auto : JSBI.toNumber(userSlippageTolerance2.multiply(1e4).quotient);
      } catch (error) {
        value = SlippageTolerance.Auto;
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value
        })
      );
    },
    [dispatch]
  );
  return [userSlippageTolerance, setUserSlippageTolerance];
}
export function useUserSlippageToleranceWithDefault(defaultSlippageTolerance) {
  const [allowedSlippage] = useUserSlippageTolerance();
  return allowedSlippage === SlippageTolerance.Auto ? defaultSlippageTolerance : allowedSlippage;
}
export function useUserHideClosedPositions() {
  const dispatch = useAppDispatch();
  const hideClosedPositions = useAppSelector((state) => state.user.userHideClosedPositions);
  const setHideClosedPositions = useCallback(
    (newHideClosedPositions) => {
      dispatch(updateHideClosedPositions({ userHideClosedPositions: newHideClosedPositions }));
    },
    [dispatch]
  );
  return [hideClosedPositions, setHideClosedPositions];
}
export function useUserTransactionTTL() {
  const { chainId } = useAccount();
  const dispatch = useAppDispatch();
  const userDeadline = useAppSelector((state) => state.user.userDeadline);
  const onL2 = isL2ChainId(chainId);
  const deadline = onL2 ? L2_DEADLINE_FROM_NOW : userDeadline;
  const setUserDeadline = useCallback(
    (userDeadline2) => {
      dispatch(updateUserDeadline({ userDeadline: userDeadline2 }));
    },
    [dispatch]
  );
  return [deadline, setUserDeadline];
}
function serializePair(pair) {
  return {
    token0: serializeToken(pair.token0),
    token1: serializeToken(pair.token1)
  };
}
export function usePairAdder() {
  const dispatch = useAppDispatch();
  return useCallback(
    (pair) => {
      dispatch(addSerializedPair({ serializedPair: serializePair(pair) }));
    },
    [dispatch]
  );
}
export function toV2LiquidityToken([tokenA, tokenB]) {
  if (tokenA.chainId !== tokenB.chainId) {
    throw new Error("Not matching chain IDs");
  }
  if (tokenA.equals(tokenB)) {
    throw new Error("Tokens cannot be equal");
  }
  if (!V2_FACTORY_ADDRESSES[tokenA.chainId]) {
    throw new Error("No V2 factory address on this chain");
  }
  return new Token(
    tokenA.chainId,
    computePairAddress({ factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId], tokenA, tokenB }),
    18,
    "UNI-V2",
    "Uniswap V2"
  );
}
export function useTrackedTokenPairs() {
  const { chainId } = useAccount();
  const supportedChainId = useSupportedChainId(chainId);
  const { data: popularTokens } = useTopTokensQuery({
    variables: {
      chain: supportedChainId ? chainIdToBackendChain({ chainId: supportedChainId }) : Chain.Ethereum,
      orderBy: TokenSortableField.Popularity,
      page: 1,
      pageSize: 100
    }
  });
  const pinnedPairs = useMemo(() => chainId ? PINNED_PAIRS[chainId] ?? [] : [], [chainId]);
  const generatedPairs = useMemo(
    () => chainId && popularTokens?.topTokens ? popularTokens.topTokens.flatMap((gqlToken) => {
      if (!gqlToken) {
        return [];
      }
      const token = gqlToCurrency(gqlToken);
      return (
        // loop though all bases on the current chain
        (BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? []).map((base) => {
          if (!token?.isNative && base.address === token?.address) {
            return null;
          } else {
            return [base, token];
          }
        }).filter((p) => p !== null)
      );
    }) : [],
    [popularTokens, chainId]
  );
  const savedSerializedPairs = useAppSelector(({ user: { pairs } }) => pairs);
  const userPairs = useMemo(() => {
    if (!chainId || !savedSerializedPairs) {
      return [];
    }
    const forChain = savedSerializedPairs[chainId];
    if (!forChain) {
      return [];
    }
    return Object.keys(forChain).map((pairId) => {
      return [deserializeToken(forChain[pairId].token0), deserializeToken(forChain[pairId].token1)];
    });
  }, [savedSerializedPairs, chainId]);
  const combinedList = useMemo(
    () => userPairs.concat(generatedPairs).concat(pinnedPairs),
    [pinnedPairs, userPairs, generatedPairs]
  );
  return useMemo(() => {
    const keyed = combinedList.reduce((memo, [tokenA, tokenB]) => {
      const sorted = tokenA.sortsBefore(tokenB);
      const key = sorted ? `${tokenA.address}:${tokenB.address}` : `${tokenB.address}:${tokenA.address}`;
      if (memo[key]) {
        return memo;
      }
      memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA];
      return memo;
    }, {});
    return Object.keys(keyed).map((key) => keyed[key]);
  }, [combinedList]);
}
