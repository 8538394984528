"use strict";
import { useCallback, useMemo } from "react";
import { useAsyncData } from "utilities/src/react/hooks";
var FeeType = /* @__PURE__ */ ((FeeType2) => {
  FeeType2["Legacy"] = "legacy";
  FeeType2["Eip1559"] = "eip1559";
  return FeeType2;
})(FeeType || {});
export var GasSpeed = /* @__PURE__ */ ((GasSpeed2) => {
  GasSpeed2["Normal"] = "normal";
  GasSpeed2["Fast"] = "fast";
  GasSpeed2["Urgent"] = "urgent";
  return GasSpeed2;
})(GasSpeed || {});
export function useTransactionGasFee(tx, speed = "urgent" /* Urgent */, skip = !tx) {
  const gasFeeFetcher = useGasFeeQuery(tx, skip);
  const { data, isLoading } = useAsyncData(gasFeeFetcher);
  return useMemo(() => {
    if (!data) {
      return { isLoading };
    }
    const params = data.type === "eip1559" /* Eip1559 */ ? {
      maxPriorityFeePerGas: data.maxPriorityFeePerGas[speed],
      maxFeePerGas: data.maxFeePerGas[speed],
      gasLimit: data.gasLimit
    } : {
      gasPrice: data.gasPrice[speed],
      gasLimit: data.gasLimit
    };
    return {
      value: data.gasFee[speed],
      isLoading,
      params
    };
  }, [data, isLoading, speed]);
}
const UNISWAP_API_URL = process.env.REACT_APP_UNISWAP_BASE_API_URL;
const isErrorResponse = (res, gasFee) => res.status < 200 || res.status > 202;
function useGasFeeQuery(tx, skip = !tx) {
  const gasFeeFetcher = useCallback(async () => {
    if (skip) {
      return;
    }
    const res = await fetch(`${UNISWAP_API_URL}/v1/gas-fee`, {
      method: "POST",
      body: JSON.stringify(tx)
    });
    const body = await res.json();
    if (isErrorResponse(res, body)) {
      return;
    }
    return body;
  }, [skip, tx]);
  return gasFeeFetcher;
}
