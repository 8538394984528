"use strict";
import { ChartModel } from "components/Charts/ChartModel";
import { CrosshairHighlightPrimitive } from "components/Charts/VolumeChart/CrosshairHighlightPrimitive";
import { CustomHistogramSeries } from "components/Charts/VolumeChart/custom-histogram-series";
import { NumberType } from "utils/formatNumbers";
export class CustomVolumeChartModel extends ChartModel {
  series;
  highlightBarPrimitive;
  hoveredLogicalIndex;
  constructor(chartDiv, params) {
    super(chartDiv, params);
    this.series = this.api.addCustomSeries(
      new CustomHistogramSeries({
        colors: params.colors,
        isMultichainExploreEnabled: params.isMultichainExploreEnabled,
        background: params.background
      })
    );
    this.series.setData(this.data);
    this.highlightBarPrimitive = new CrosshairHighlightPrimitive({
      color: params.theme.surface3,
      crosshairYPosition: params.headerHeight,
      useThinCrosshair: params.useThinCrosshair
    });
    this.series.attachPrimitive(this.highlightBarPrimitive);
    this.updateOptions(params);
    this.fitContent();
    this.api.subscribeCrosshairMove((param) => {
      if (param?.logical !== this.hoveredLogicalIndex) {
        this.hoveredLogicalIndex = param?.logical;
        this.series.applyOptions({
          hoveredLogicalIndex: this.hoveredLogicalIndex ?? -1
          // -1 is used because series will use prev value if undefined is passed
        });
      }
    });
  }
  updateOptions(params, options) {
    const stackedVolumeChartOptions = {
      localization: {
        locale: params.locale,
        priceFormatter: (price) => params.format.formatFiatPrice({ price, type: NumberType.ChartVolumePriceScale })
      },
      rightPriceScale: {
        visible: false
      },
      handleScale: false,
      handleScroll: false,
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: false,
          labelVisible: false
        }
      },
      ...options
    };
    super.updateOptions(params, stackedVolumeChartOptions);
    const { data } = params;
    if (this.data !== data) {
      this.data = data;
      this.series.setData(data);
      this.fitContent();
    }
    this.series.applyOptions({
      priceFormat: {
        type: "volume"
      },
      priceLineVisible: false,
      lastValueVisible: false
    });
    this.series.priceScale().applyOptions({
      scaleMargins: {
        top: 0.3,
        bottom: 0
      }
    });
  }
}
