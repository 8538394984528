"use strict";
import { useQueries } from "@tanstack/react-query";
import { chainIdToBackendChain } from "constants/chains";
import { apolloClient } from "graphql/data/apollo/client";
import { gqlTokenToCurrencyInfo } from "graphql/data/types";
import { apolloQueryOptions } from "graphql/data/util";
import { useAccount } from "hooks/useAccount";
import { useTokenContractsConstant } from "hooks/useTokenContractsConstant";
import { useMemo } from "react";
import {
  SafetyLevel,
  TokenDocument
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { hasURL } from "utils/urlChecks";
function getUniqueAddressesFromPositions(positions) {
  return Array.from(
    new Set(positions.reduce((acc, position) => acc.concat(position.token0, position.token1), []))
  );
}
function getPositionCurrencyInfosQueryOptions(position, chainId) {
  return apolloQueryOptions({
    queryKey: ["positionCurrencyInfo", position],
    queryFn: async () => {
      const queries = [
        apolloClient.query({
          query: TokenDocument,
          variables: {
            address: position.token0,
            chain: chainIdToBackendChain({ chainId })
          },
          fetchPolicy: "cache-first"
        }),
        apolloClient.query({
          query: TokenDocument,
          variables: {
            address: position.token1,
            chain: chainIdToBackendChain({ chainId })
          },
          fetchPolicy: "cache-first"
        })
      ];
      const [currency0, currency1] = await Promise.all(queries);
      return {
        position,
        currency0Info: gqlTokenToCurrencyInfo(currency0.data.token),
        currency1Info: gqlTokenToCurrencyInfo(currency1.data.token)
      };
    }
  });
}
export function useFilterPossiblyMaliciousPositions(positions) {
  const { chainId } = useAccount();
  const nonListPositionTokenAddresses = useMemo(() => getUniqueAddressesFromPositions(positions), [positions]);
  const positionCurrencyInfos = useQueries({
    queries: positions.map((position) => getPositionCurrencyInfosQueryOptions(position, chainId))
  });
  const symbolCallStates = useTokenContractsConstant(nonListPositionTokenAddresses, "symbol");
  const addressesToSymbol = useMemo(() => {
    const result = {};
    for (let i = 0; i < nonListPositionTokenAddresses.length; i++) {
      const callResult = symbolCallStates[i]?.result;
      if (!callResult) {
        continue;
      }
      const address = nonListPositionTokenAddresses[i];
      result[address] = callResult;
    }
    return result;
  }, [nonListPositionTokenAddresses, symbolCallStates]);
  return useMemo(() => {
    return positionCurrencyInfos.map((result) => {
      if (!result.data) {
        return void 0;
      }
      const { currency0Info, currency1Info, position } = result.data;
      let tokensInListCount = 0;
      if (!currency0Info?.isSpam && currency0Info?.safetyLevel === SafetyLevel.Verified) {
        tokensInListCount++;
      }
      if (!currency1Info?.isSpam && currency1Info?.safetyLevel === SafetyLevel.Verified) {
        tokensInListCount++;
      }
      if (tokensInListCount === 2) {
        return position;
      }
      let urlSymbolCount = 0;
      if (hasURL(currency0Info?.currency?.symbol ?? addressesToSymbol[position.token0])) {
        urlSymbolCount++;
      }
      if (hasURL(currency1Info?.currency?.symbol ?? addressesToSymbol[position.token1])) {
        urlSymbolCount++;
      }
      if (tokensInListCount === 1 && urlSymbolCount < 2) {
        return position;
      }
      return urlSymbolCount === 0 ? position : void 0;
    }).filter((position) => !!position);
  }, [addressesToSymbol, positionCurrencyInfos]);
}
