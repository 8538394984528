"use strict";
import { createContext, useMemo } from "react";
import { ALL_NETWORKS_ARG } from "uniswap/src/data/rest";
import { useExploreStatsQuery } from "uniswap/src/data/rest/exploreStats";
import { useProtocolStatsQuery } from "uniswap/src/data/rest/protocolStats";
export const giveExploreStatDefaultValue = (value, defaultValue = 0) => {
  return value ?? defaultValue;
};
export const ExploreContext = createContext({
  exploreStats: {
    data: void 0,
    isLoading: false,
    error: false
  },
  protocolStats: {
    data: void 0,
    isLoading: false,
    error: false
  }
});
export function ExploreContextProvider({
  chainId,
  children
}) {
  const {
    data: exploreStatsData,
    isLoading: exploreStatsLoading,
    error: exploreStatsError
  } = useExploreStatsQuery({
    chainId: chainId ? chainId.toString() : ALL_NETWORKS_ARG
  });
  const {
    data: protocolStatsData,
    isLoading: protocolStatsLoading,
    error: protocolStatsError
  } = useProtocolStatsQuery({
    chainId: chainId ? chainId.toString() : ALL_NETWORKS_ARG
  });
  const exploreContext = useMemo(() => {
    return {
      exploreStats: {
        data: exploreStatsData,
        isLoading: exploreStatsLoading,
        error: !!exploreStatsError
      },
      protocolStats: {
        data: protocolStatsData,
        isLoading: protocolStatsLoading,
        error: !!protocolStatsError
      }
    };
  }, [
    exploreStatsData,
    exploreStatsError,
    exploreStatsLoading,
    protocolStatsData,
    protocolStatsError,
    protocolStatsLoading
  ]);
  return <ExploreContext.Provider value={exploreContext}>{children}</ExploreContext.Provider>;
}
