"use strict";
import Column from "components/Column";
import { CheckMark } from "components/Icons/CheckMark";
import { LoaderV3 } from "components/Icons/LoadingSpinner";
import Row, { RowBetween } from "components/Row";
import styled, { keyframes } from "lib/styled-components";
import { useEffect, useState } from "react";
import { ExternalLink, ThemedText } from "theme/components";
export var StepStatus = /* @__PURE__ */ ((StepStatus2) => {
  StepStatus2[StepStatus2["PREVIEW"] = 0] = "PREVIEW";
  StepStatus2[StepStatus2["ACTIVE"] = 1] = "ACTIVE";
  StepStatus2[StepStatus2["IN_PROGRESS"] = 2] = "IN_PROGRESS";
  StepStatus2[StepStatus2["COMPLETE"] = 3] = "COMPLETE";
  return StepStatus2;
})(StepStatus || {});
const ringAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;
const Ring = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  border-radius: 50%;
  animation: ${({ $animation }) => $animation} 1.5s linear infinite;
`;
const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  filter: ${({ isActive }) => `grayscale(${isActive ? 0 : 1})`};
  opacity: ${({ isActive }) => isActive ? "1" : "0.5"};
`;
function RippleAnimation({ rippleColor }) {
  if (!rippleColor) {
    return null;
  }
  return <div data-testid="icon-ripple-animation"><Ring $borderColor={rippleColor} $animation={ringAnimation} /></div>;
}
function Icon({ stepStatus, icon, rippleColor }) {
  if (stepStatus === 2 /* IN_PROGRESS */) {
    return <LoaderV3 size="24px" stroke={rippleColor} fill={rippleColor} data-testid="loader-icon" />;
  }
  return <div>
    {stepStatus === 1 /* ACTIVE */ && <RippleAnimation rippleColor={rippleColor} />}
    <IconWrapper isActive={stepStatus === 1 /* ACTIVE */} data-testid="step-icon">{icon}</IconWrapper>
  </div>;
}
function Title({
  stepStatus,
  stepDetails,
  isTimeRemaining
}) {
  switch (stepStatus) {
    case 0 /* PREVIEW */:
      return <ThemedText.LabelSmall>{stepDetails.previewTitle}</ThemedText.LabelSmall>;
    case 1 /* ACTIVE */:
      return <ThemedText.BodySmall>{isTimeRemaining ? stepDetails.actionRequiredTitle : stepDetails.delayedStartTitle}</ThemedText.BodySmall>;
    case 2 /* IN_PROGRESS */:
      return <ThemedText.BodySmall>{isTimeRemaining ? stepDetails.inProgressTitle : stepDetails.delayedEndTitle}</ThemedText.BodySmall>;
    case 3 /* COMPLETE */:
      return <ThemedText.LabelSmall>{stepDetails.previewTitle}</ThemedText.LabelSmall>;
    default:
      return null;
  }
}
const MonospacedTimer = styled(ThemedText.LabelSmall)`
  font-variant-numeric: tabular-nums;
  padding-right: 8px;
`;
function Timer({ secondsRemaining }) {
  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining % 60;
  const minutesText = minutes < 10 ? `0${minutes}` : minutes;
  const secondsText = seconds < 10 ? `0${seconds}` : seconds;
  const timerText = `${minutesText}:${secondsText}`;
  return <MonospacedTimer data-testid="step-timer">{timerText}</MonospacedTimer>;
}
const Container = styled(RowBetween)`
  padding-right: 16px;
`;
const StyledExternalLink = styled(ExternalLink)`
  font-size: 12px;
  font-weight: 485px;
  line-height: 16px;
`;
export function Step({ stepStatus, stepDetails }) {
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  useEffect(() => {
    if (stepStatus === 1 /* ACTIVE */ && stepDetails?.timeToStart) {
      setSecondsRemaining(stepDetails.timeToStart);
    } else if (stepStatus === 2 /* IN_PROGRESS */ && stepDetails?.timeToEnd) {
      setSecondsRemaining(stepDetails.timeToEnd);
    } else {
      setSecondsRemaining(null);
      return;
    }
    const timer = setInterval(() => {
      setSecondsRemaining((prevSecondsRemaining) => {
        if (prevSecondsRemaining && prevSecondsRemaining > 0) {
          return prevSecondsRemaining - 1;
        }
        clearInterval(timer);
        return 0;
      });
    }, 1e3);
    return () => clearInterval(timer);
  }, [stepStatus, stepDetails.timeToStart, stepDetails.timeToEnd]);
  return <Container>
    <Row align="center" gap="12px" height={40} padding="8px 16px">
      <Icon stepStatus={stepStatus} icon={stepDetails.icon} rippleColor={stepDetails.rippleColor} />
      <Column>
        <Title
          stepStatus={stepStatus}
          stepDetails={stepDetails}
          isTimeRemaining={secondsRemaining === null || secondsRemaining > 0}
        />
        {stepStatus === 1 /* ACTIVE */ && stepDetails.learnMoreLinkHref && <StyledExternalLink href={stepDetails.learnMoreLinkHref || ""}>{stepDetails.learnMoreLinkText}</StyledExternalLink>}
      </Column>
    </Row>
    {secondsRemaining !== null && <Timer secondsRemaining={secondsRemaining} />}
    {stepStatus === 3 /* COMPLETE */ && <CheckMark />}
  </Container>;
}
