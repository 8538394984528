"use strict";
import { CurrencyAmount, Fraction, Percent } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { FeeAmount } from "@uniswap/v3-sdk";
import {
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BIPS_BASE,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ONE_HUNDRED_PERCENT,
  ZERO_PERCENT
} from "constants/misc";
const THIRTY_BIPS_FEE = new Percent(30, BIPS_BASE);
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(THIRTY_BIPS_FEE);
export function computeRealizedPriceImpact(trade) {
  const realizedLpFeePercent = computeRealizedLPFeePercent(trade);
  return trade.priceImpact.subtract(realizedLpFeePercent);
}
function computeRealizedLPFeePercent(trade) {
  let percent;
  if (trade.swaps[0].route.pools instanceof Pair) {
    percent = ONE_HUNDRED_PERCENT.subtract(
      trade.swaps.reduce(
        (currentFee) => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
        ONE_HUNDRED_PERCENT
      )
    );
  } else {
    percent = ZERO_PERCENT;
    for (const swap of trade.swaps) {
      const { numerator, denominator } = swap.inputAmount.divide(trade.inputAmount);
      const overallPercent = new Percent(numerator, denominator);
      const routeRealizedLPFeePercent = overallPercent.multiply(
        ONE_HUNDRED_PERCENT.subtract(
          swap.route.pools.reduce((currentFee, pool) => {
            const fee = pool instanceof Pair ? (
              // not currently possible given protocol check above, but not fatal
              FeeAmount.MEDIUM
            ) : pool.fee;
            return currentFee.multiply(ONE_HUNDRED_PERCENT.subtract(new Fraction(fee, 1e6)));
          }, ONE_HUNDRED_PERCENT)
        )
      );
      percent = percent.add(routeRealizedLPFeePercent);
    }
  }
  return new Percent(percent.numerator, percent.denominator);
}
export function computeRealizedLPFeeAmount(trade) {
  if (trade) {
    const realizedLPFee = computeRealizedLPFeePercent(trade);
    return CurrencyAmount.fromRawAmount(trade.inputAmount.currency, trade.inputAmount.multiply(realizedLPFee).quotient);
  }
  return void 0;
}
const IMPACT_TIERS = [
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  ALLOWED_PRICE_IMPACT_LOW
];
export function warningSeverity(priceImpact) {
  if (!priceImpact) {
    return 0;
  }
  if (priceImpact.lessThan(0)) {
    return 0;
  }
  let impact = IMPACT_TIERS.length;
  for (const impactLevel of IMPACT_TIERS) {
    if (impactLevel.lessThan(priceImpact)) {
      return impact;
    }
    impact--;
  }
  return 0;
}
export function getPriceImpactWarning(priceImpact) {
  if (priceImpact.greaterThan(ALLOWED_PRICE_IMPACT_HIGH)) {
    return "error";
  }
  if (priceImpact.greaterThan(ALLOWED_PRICE_IMPACT_MEDIUM)) {
    return "warning";
  }
  return;
}
export function getPriceImpactColor(priceImpact) {
  switch (getPriceImpactWarning(priceImpact)) {
    case "error":
      return "critical";
    case "warning":
      return "deprecated_accentWarning";
    default:
      return void 0;
  }
}
