"use strict";
import { Web3Provider } from "@ethersproject/providers";
import { useAccount } from "hooks/useAccount";
import { useMemo } from "react";
import { useClient, useConnectorClient } from "wagmi";
const providers = /* @__PURE__ */ new WeakMap();
function clientToProvider(client, chainId) {
  if (!client) {
    return void 0;
  }
  const { chain, transport } = client;
  const ensAddress = chain.contracts?.ensRegistry?.address;
  const network = chain ? {
    chainId: chain.id,
    name: chain.name,
    ensAddress
  } : chainId ? { chainId, name: "Unsupported" } : void 0;
  if (!network) {
    return void 0;
  }
  if (providers?.has(client)) {
    return providers.get(client);
  } else {
    const provider = new Web3Provider(transport, network);
    providers.set(client, provider);
    return provider;
  }
}
export function useEthersProvider({ chainId } = {}) {
  const account = useAccount();
  const { data: client } = useConnectorClient({ chainId });
  const disconnectedClient = useClient({ chainId });
  return useMemo(
    () => clientToProvider(account.chainId !== chainId ? disconnectedClient : client ?? disconnectedClient, chainId),
    [account.chainId, chainId, client, disconnectedClient]
  );
}
export function useEthersWeb3Provider({ chainId } = {}) {
  const { data: client } = useConnectorClient({ chainId });
  return useMemo(() => clientToProvider(client, chainId), [chainId, client]);
}
