"use strict";
import { useScreenSize } from "hooks/screenSize";
import { BREAKPOINTS } from "theme";
export const NAV_BREAKPOINT = {
  isMobileDrawer: 450,
  areTabsVisible: BREAKPOINTS.sm,
  showMobileBar: BREAKPOINTS.lg + 1,
  collapseSearchBar: BREAKPOINTS.md
};
export function useIsMobileDrawer() {
  const isScreenSize = useScreenSize();
  const isMobileDrawer = !isScreenSize["navDropdownMobileDrawer"];
  return isMobileDrawer;
}
export function useTabsVisible() {
  const isScreenSize = useScreenSize();
  const areTabsVisible = isScreenSize["sm"];
  return areTabsVisible;
}
